
import axios from "axios"
import { getToken } from "./Auth";

/**
 * Constante do Axios
 */
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async config => {
    
    const token = getToken();

    config.headers.Accept = `application/json`;

    if (token) {
      config.headers.Authorization = token;
    }

    return config;
});

export default api;