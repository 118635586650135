import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import {Row, Form, Col } from 'react-bootstrap';

import FormParent from '../../core/components/form/FormParent'

import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

class UserFormEdit extends FormParent {
   
   constructor(props){
      const state = {
         name: '',
         email: '',
         password: '',
         tenant_id: '',
         profile: '',
         company_id: '',
         tenant_name: ''
      }
      const titlePage     = "Editar Cliente";
      const apiDefault    = "/user/read/id";
      const routeList     = "/user";

      super(props, state, titlePage, apiDefault, routeList)
   }

   render() {
      return (
         <>
            {super.render(
               <div className="card-body">
                  <Row>
                     <Col xs={4}>
                        <h1>
                           <Form.Label>DADOS DO USUÁRIO</Form.Label>
                        </h1>
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={4}>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control type="text" name="name" defaultValue={this.state.name} onChange={this.handleChange} />
                     </Col>
                     <Col xs={4}>
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control type="text" name="email" defaultValue={this.state.email} onChange={this.handleChange} disabled />
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={4}>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" defaultValue="**********" onChange={this.handleChange} />
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={4}>
                        <h1>
                           <Form.Label>DADOS TÉCNICOS</Form.Label>
                        </h1>
                     </Col>
                  </Row>
               </div>
            )}
         </>
      )
   }
}

const UserFormEditComponent = props => {
   const match = { params: useParams() };
   const navigate = useNavigate();
   
   return <UserFormEdit match={match} navigate={navigate} {...props} />
}

export default UserFormEditComponent;