import React from 'react'
import FormPassword from '../../core/components/form/formForgot';
import { Row, Form, Col, Card } from 'react-bootstrap';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

class ForgotPassword extends FormPassword {

    constructor(props) {
        const state = { email: "" }
        const apiReset = "/password/forgot"
        const routeList = {
            success: "/reset_password",
            fail: "/reset_password"
        }
        const context = {
            success: 'Código enviado, caso seu email esteja cadastrado, ele receberá o código de segurança!',
            fail: 'Código enviado, caso seu email esteja cadastrado, ele receberá o código de segurança!',
            formFail: 'Por favor, preencha todos os campos!',
            title: 'forgot'
        }

        super(props, state, apiReset, routeList, context)
    }

    render() {
        return (
            <>
                {
                    super.render(
                        <>
                            <div className='forgot main-panel'>
                                <Card className="text-center card-forgot" 
                                style={{ width: '40vw', backgroundColor: '#f1fcff', borderRadius: '10px' }}>
                                    <Card.Header className='forgot'>
                                        <h1>Atualização de senha</h1>
                                    </Card.Header>

                                    <Card.Body>
                                        <Card.Title className='forgot'>
                                            <h3>Informe o email da conta</h3>
                                        </Card.Title>

                                        <Form.Control style={{ width: '40%', margin: 'auto' }} type="text" placeholder='Email' name="email" defaultValue={this.state.email} onChange={this.handleChange} />

                                        <Row>
                                            <Col>
                                                <div className="submit">
                                                    <input className="submit" value="Confirmar" type="button" onClick={this.handleSubmit} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Card.Footer>
                                        <Card.Text style={{marginTop: '15px', color: '#5c4956'}}>
                                            Um código de segurança irá ser enviado ao email informado!
                                        </Card.Text>
                                        </Card.Footer>
                                    </Card.Body>
                                </Card>
                            </div>
                        </>
                    )
                }
            </>
        )
    }
}

const ForgotPasswordComponent = props => {
    const match = {
       params: useParams()
    }
    const navigate = useNavigate()

    const [searchParams] = useSearchParams();
 
    return <ForgotPassword match={match} navigate={navigate} searchParams={searchParams} {...props} />
 }
 
export default ForgotPasswordComponent