import React from 'react'
import { Row, Form, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { localStoragePersonalized } from '../../core/components/api/Auth';

import FormParent from '../../core/components/form/FormParent'


class UserForm extends FormParent {

   constructor(props) {
      const state = {
         name: "",
         email: "",
         password: "",
         tenant_id: localStoragePersonalized.getTenantId,
         tenantDefault: [],
         profile: "user",
         companyDefault: [],
         company_id: "",

      }
      const titlePage = "Usuário"
      const apiDefault = "/user/create"
      const routeList = "/user"

      super(props, state, titlePage, apiDefault, routeList)

      this.master = localStoragePersonalized.getMasterId
   }

   componentDidMount() {

      this.selectPreview('/companies/list', 'companyDefault', 'company_id', '')

      if (this.master) {
         this.selectPreview('/tenant/list', 'tenantDefault', 'tenant_id', '')
      }
   }

   render() {
      return (
         <>
            {super.render(
               <div className="card-body">
                  <Row>
                     <Col xs={4}>
                        <h1>
                           <Form.Label>DADOS DO USUÁRIO</Form.Label>
                        </h1>
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={4}>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control type="text" name="name" defaultValue={this.state.name} onChange={this.handleChange} />
                     </Col>
                     <Col xs={4}>
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control type="text" name="email" defaultValue={this.state.email} onChange={this.handleChange} />
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={4}>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" defaultValue={this.state.password} onChange={this.handleChange} />
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={4}>
                        <h1>
                           <Form.Label>DADOS TÉCNICOS</Form.Label>
                        </h1>
                     </Col>
                  </Row>
                  <Row>
                     {
                        this.master ? (
                           <Col xs={4}>
                              <Form.Label>Tenant</Form.Label>
                              <Form.Control as="select" name="tenant_id" defaultValue={this.state.responsavel} onChange={this.handleChange} >
                                 <option value="">Selecione o tenant</option>
                                 {this.state.tenantDefault.map(op => {
                                    return <option key={op.tenant_id} value={op.tenant_id}>{op.tenant_name}</option>
                                 })}
                              </Form.Control>
                           </Col>
                        ) : <div></div>
                     }

                     <Col xs={4}>
                        <Form.Label>Perfil de usuário</Form.Label>
                        {
                           this.master ? <Form.Control as="select" name="profile" defaultValue={this.state.responsavel} onChange={this.handleChange} >
                              <option value="user">Agente</option>
                              <option value="admin">Admin</option>
                           </Form.Control> :
                              <Form.Control as="select" name="profile" defaultValue={this.state.responsavel} onChange={this.handleChange} >
                                 <option value="user">Agente</option>
                              </Form.Control>
                        }
                     </Col>

                     <Col xs={4}>
                        <Form.Label>Grupo</Form.Label>
                        <Form.Control as="select" name="company_id" defaultValue={this.state.company_id} onChange={this.handleChange} >
                           <option value="">Selecione um Grupo</option>
                           {this.state.companyDefault.map(companies =>{
                              return <option key={companies.id} value={companies.id}>{companies.
                                 name}</option>
                           })}
                        </Form.Control>
                     </Col>   
                  </Row>
               </div>
            )}
         </>
      )
   }
}

const UserFormComponent = props => {
   const navigate = useNavigate()

   return <UserForm navigate={navigate} {...props} />
}

export default UserFormComponent