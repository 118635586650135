import React       from 'react'
import { Button }  from 'react-bootstrap';

import IconGridRoute    from '../../core/components/icon/IconGridRoute'
import IconLabelStatus  from '../../core/components/icon/IconLabelStatus'
import GridParent       from "../../core/components/grid/GridParent"

export default class Contact extends GridParent {
     
   constructor(){

      const titlePage      = "Atendimentos"
      const apiList    = "/contact/pages"
      const apiStatus  = "/atendimentos/detalhes"
      super(titlePage, apiList, '', apiStatus)
   }

   render() {

		return (
         <>
           {super.render(
               <>
                  <div className="card" style={{marginTop: '1rem'}}>
                     <div className="card-body">
                        <div className="row">
                           <div className="col-lg-3">
                              <input type="text" 
                                    name="name" 
                                    className="form-control" 
                                    maxLength="40" 
                                    placeholder="Buscar" 
                                    onChange={this.handleFilter} />				
                           </div>
                           <div className="col-lg-3">
                              <select className="form-control" name="active" onChange={this.handleFilter}>
                                 <option value={2}>Todos</option>
                                 <option value={1}>Ativos</option>
                                 <option value={0}>Desativados</option>
                              </select>
                           </div>	
                           <div className="col-lg-3">
                              <Button className="btn" onClick={this.sendRequest}>
                                 <i className="fas fa-search"></i>
                              </Button>
                           </div>
                        </div>				
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-body">	
                        <div className="table-responsive">
                           <table className="table table-striped table-hover text-nowrap">
                              <thead>
                                 <tr>
                                    <th>ID</th>
                                    <th>Data</th>
                                    <th>Hora</th>
                                    <th>Canal</th>
                                    <th>Cliente</th>
                                    <th>Agente</th>
                                    <th>Status</th>
                                    
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.data.map(row => {      
                                    return <tr key={row.contact_id}>
                                       <td>{row.contact_id}</td>
                                       <td>{row.contact_date}</td>
                                       <td>{row.contact_hour}</td>
                                       <td>{row.channel.toUpperCase()}</td>
                                       <td>{row['client.name']}</td>
                                       <td>{row['user.email']}</td>
                                       <td>
                                          <IconLabelStatus value={row.active} />
                                       </td>
                                       <td align="right">
                                          <IconGridRoute url={this.apiStatus}  id={row.contact_id} icon="fas fa-search" />
                                       </td>
                                    </tr>
                                    
                                } )}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </>
            )} 
         </>
		);
	}
};