import React, {Component} from 'react'

import customApi     from '../api/CustomApi'
import Swal from 'sweetalert2';

import "./form.css"

export default class FormPassword extends Component {
 
   constructor(props, initialState, apiUrl, routeList, context){

      super(props)

      this.initialState = initialState;
      this.apiUrl       = apiUrl;
      this.routeList    = routeList
      this.cenary       = context
   
      this.state        = initialState;

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
   }

   componentDidMount() {
      if(this.cenary.title === 'reset'){
         this.setState({security_code: this.props.searchParams.get('code')}) 
         this.setState({email: this.props.searchParams.get('email')}) 
      }
   }

   /**
    * Realiza a renderização do field do formulario
    * 
    * @param {*} event 
    */
   handleChange(event) {
      const name  = event.target.name; 
      const value = event.target.value;
      this.setState({[name]: value})
   }

   validFields(state){
      const fields = Object.getOwnPropertyNames(state)
      return fields.filter(value => state[value] === "")
   }

   /**
    * Remove campos do state que não são esperados no servidor
    */
   formStateValidation(state) {
         let payload = false
         
         switch (this.cenary.title) {
            case 'reset':
               payload = {...state}
               delete payload.user
               break;

            case 'forgot':
               payload = {...state}
               delete payload.user
               break;
   
            default:
               break;
         }
   
         return payload || state;
      }

   /**
    * Realiza o submit do formulario
    * 
    * @param {*} event 
    */
    handleSubmit() {
      if(this.validFields(this.state).length < 1){
         // Requisição gerar token para resete de senha
         customApi.post(this.apiUrl, this.state, (res) => {
            Swal.fire('', this.cenary.success)
            this.props.navigate(this.routeList.success)
         }, (error) => {
            Swal.fire('', this.cenary.fail)
            this.props.navigate(this.routeList.fail)
         })
      }else{         
         Swal.fire('', this.cenary.formFail ?? 'Erro inesperado!')
      }
   }
 
   render(formContent) {
		return(
         <>
            {formContent}
         </>
      )
   }
}