import React from 'react'
import { Card } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import FormNotification from '../../core/components/form/formNotification'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

class SignIn extends FormNotification {

   constructor(props) {
      const state = { email: "" }
      const apiDefault = "/notification/ticket/unsubscribe"


      super(props, state, apiDefault)
   }

   render() {
      return (
         <>
            {
               super.render(
                  <>
                     {<div className='Signin main-panel'>
                     <Card style={{ width: '40vw', backgroundColor: '#ffffff', borderRadius: '10px' }}
                     >
                        <div className="login-container" style={{ margin: '2rem' }}>
                           <p>Ao cancelar sua inscrição, você não receberá mais notificações do CRM no seu e-mail.</p>
                           <form onSubmit={this.handleSubmit}>
                              <label>{this.state.email}</label>
                              <div className="submit">
                                 <input className="submit" value="Confirmar" type="button" onClick={this.handleSubmit} />
                              </div>
                           </form>
                           
                        </div>
                        </Card>
                     </div>}
                  </>
               )
            }
         </>
      )
   }
}


const LoginComponent = props => {
   const match = {
      params: useParams()
   }
   const navigate = useNavigate()

   const [searchParams] = useSearchParams();

   return <SignIn match={match} navigate={navigate} searchParams={searchParams} {...props} />
}

export default LoginComponent