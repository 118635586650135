import React, {Component} from 'react';

export default class IconLabelStatus extends Component {

    render() {

        let icon
    
        if(this.props.value)
            icon = <i className="fas fa-thumbs-up azul-athan-dark"></i>
        else
            icon = <i className="fas fa-thumbs-down orange"></i>

        return (icon)
    }
};