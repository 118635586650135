import React, {Component} from 'react';
import {Route, Routes} from "react-router-dom";

import SignIn from '../../../modules/signin/login';
import Redirect from '../../../modules/signin/redirect';
import ForgotPassword from '../../../modules/signin/forgot-password';
import ResetPassword from '../../../modules/signin/reset-password';
import UnsubscribeNootification from '../../../modules/notification/unsubscribe-notification'



const Route404 = ({location}) => (
    <div><h4>Rota não encontrada...<br /><code className="green">{location.pathname}</code></h4></div>
);

export default class MenuRouteres extends Component {
    
    render() {
        return (
            <Routes>
                <Route path="/"                      element={<SignIn />} />
                <Route path="/login"                 element={<SignIn />} />
                <Route path="/login/redirect"        element={<Redirect />} />
                <Route path="/forgot_password"       element={<ForgotPassword />} />
                <Route path="/reset_password"        element={<ResetPassword />} />
                <Route path="/unsubscribe"           element={<UnsubscribeNootification />} />
                <Route component={Route404} />
            </Routes>
        )
    }
};