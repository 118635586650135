export const TOKEN_KEY        = "@wf-Token";
export const TOKEN_KEY_USER   = "@wf-User";
export const isAuthenticated  = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken         = () => localStorage.getItem(TOKEN_KEY);

export const login = (token, user) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(TOKEN_KEY_USER, JSON.stringify(user));
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_KEY_USER);
};

export const localStoragePersonalized = {
  getMasterId: JSON.parse(localStorage.getItem('@wf-User'))?.master_id ?? '',
  getUserId:  JSON.parse(localStorage.getItem('@wf-User'))?.user_id ?? '',
  getTenantId: JSON.parse(localStorage.getItem('@wf-User'))?.tenant_id ?? '',
  getCompanyId: JSON.parse(localStorage.getItem('@wf-User'))?.company_id ?? ''
};

