import React, {Component} from 'react';
import { useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2'

import customApi    from "../api/CustomApi"
import notification from "../notification/Notification"

class ChangeStatus extends Component {

    changeStatus = () => {
      
      let api = `${this.props.url}/${this.props.id}`;
      
      Swal.fire({title: "Alterar o status do registro ?", showCancelButton: true})
          .then((result) => {

            if (result.value)
                customApi.patch(api, {}, (response) => {
                    notification.success(response.data.message)
                    this.props.navigate(0)
                });
        });
    }  
 
    render() {

        return (
          <a onClick={this.changeStatus} className="link_grid">
              <i className="fas fa-toggle-on azul-athan-dark"></i>
          </a>
        );
    }
};

const ChangeStatusComponent = props => {
    const navigate = useNavigate()
  
    return <ChangeStatus navigate={navigate} {...props} />
 }
 
export default ChangeStatusComponent