import React       from 'react'
import { Button }  from 'react-bootstrap';

import GridParent       from "../../core/components/grid/GridParent"

export default class CustomerTableTickets extends GridParent {
     
   constructor({ id }){
      const titlePage  = "Contatos"
      const apiList    = `/contact/pages/customer/${id}`

      super(titlePage, apiList)
   }

    formatDateBr(date) {
      if(date) {
         date = date.split('-');
         return `${date[2]}-${date[1]}-${date[0]}`;
      }
    }

   render() {

      return (
         <>
           {super.render(
               <>
                  <div className="card">
                     <div className="card-body">
                        <div className="row">
                           <div className="col-lg-3">
                              <input 
                                 type="date" 
                                 name="contact_date" 
                                 className="form-control"
                                 onChange={this.handleFilter} 
                              />           
                           </div>
                           <div className="col-lg-3">
                              <select className="form-control" name="active" onChange={this.handleFilter}>
                                 <option value={2}>Todos</option>
                                 <option value={1}>Ativos</option>
                                 <option value={0}>Desativados</option>
                              </select>
                           </div>   
                           <div className="col-lg-3">
                              <Button className="btn m-0" onClick={this.sendRequest}>
                                 <i className="fas fa-search"></i>
                              </Button>
                           </div>
                        </div>            
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-body">   
                        <div className="table-responsive">
                           <table className="table table-striped table-hover text-nowrap">
                              <thead>
                                 <tr>
                                    <th>ID Contato</th>
                                    <th>Responsável</th>
                                    <th>Canal</th>
                                    <th>Notas</th>
                                    <th>ID Ticket</th>
                                    <th>Data</th>
                                    <th>Ações</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.data.map(row => 
                                    <tr key={row.contact_id}>
                                        <td>{row.contact_id}</td>
                                        <td>{row['user.name']}</td>
                                        <td>{row.channel}</td>
                                        <td>{row.notes}</td>
                                        <td>{row.ticket_id}</td>
                                        <td>
                                            {row.contact_date &&
                                                <span>
                                                    {this.formatDateBr(row.contact_date)}<br/>
                                                    {row.contact_hour}
                                                </span>
                                            }
                                        </td>
                                       <td align="right">

                                       </td>
                                    </tr>
                                 )}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </>
            )} 
         </>
      );
   }
};