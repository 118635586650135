import React, { Component } from 'react'
import ReactPaginate from 'react-paginate'
import customApi from "../api/CustomApi"
import PageHeader from '../pageHeader/PageHeader'

import "./grid.css"

export default class GridParent extends Component {

   state = {
      data: [],
      company: [],
      filter: {
         active: 2,
      },
      offset: 1,
      pagination: { limit: 15, offset: 1, total: 0, pages: 0, page: 0 }
   };

   constructor(titlePage, apiList, routeForm, apiStatus, routeView) {

      super();

      this.titlePage = titlePage;
      this.apiList = apiList;
      this.routeForm = routeForm;
      this.apiStatus = apiStatus;
      this.routeView = routeView;
      this.routeCompany = '/companies/list';
   }

   componentDidMount() {
      this.sendRequest();
   }

   /**
    * Mensagem padrao de paginação de resultados
    * 
    * @returns message
    */
   getMessagePagination = () => {
      const obj = this.state.pagination;

      return obj.total > 0 ? `Total de ${obj.total} registros encontrados.` : "Nenhum registro encontrado.";
   }

   /**
     * Processamento dos filtros do grid.
     * 
     * @param event
     */
   handleFilter = (event) => {
      
      if(event.target.value === '') {
         this.setState({ filter: {} });
      } else {

        let filter = {...this.state.filter}

        this.setState(prevState => ({
          filter: {
            ...prevState.filter,
            [event.target.name]: event.target.value
          }
        }))
      }
   };

   /**
     * Processamento dos filtros do grid a partir da paginação
     * 
     * @param data
     */
   handlePageClick = (data) => this.sendRequest(data)


   requestFormatted = (data) => {

      /** Formatação dos filtros em querys */
      const parameter = Object.getOwnPropertyNames(this.state.filter)

      console.log("PARAMETER", parameter)

      const querys = parameter.map(value => `&${value}=${this.state.filter[value]}`).join('')
      /** Formatação da paginação e adição das querys */
      const page = data && data.selected ? Math.ceil(data.selected) : 0
      let pagination = `?page=${page}&pageSize=${this.state.pagination.limit}` + querys;

      console.log("PAGINATION", pagination)

      return {
         pagination
      }
   }


   requestCompanies = (data) => {

    let companies
    customApi.get(this.routeCompany, {}, (response) => {
      companies = response.data

      this.setState({company: response.data})
    })

   }

   /**
    * Processamento da execução do request
    * 
    * @param data
    */
   sendRequest = (data) => {

      const payload = this.requestFormatted(data)
      const company = this.requestCompanies(data)

      console.log('company', this.state.company)

      customApi.get(this.apiList + payload.pagination, {}, (response) => {

         this.setState({
            data: response.data.rows,
            pagination: response.data.pagination
         });
      });

   };

   render(gridContent) {

      return (
         <>
            <PageHeader title={this.titlePage} routeForm={this.routeForm} />

            {gridContent}

            <div className="card-footer">
               <div className="container_pagination">
                  <div className="items">
                     <div className="row">
                        <div className="col-12"><p>{this.getMessagePagination()}</p></div>
                     </div>
                     <div className="row">
                        <div className="col-12">
                           <ReactPaginate
                              previousLabel={'Anterior'}
                              nextLabel={'Próxima'}
                              breakLabel={'...'}
                              pageCount={this.state.pagination.pages}
                              marginPagesDisplayed={5}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              breakClassName={'page-item'}
                              breakLinkClassName={'page-link'}
                              pageClassName={'page-item'}
                              pageLinkClassName={'page-link'}
                              previousClassName={'page-item'}
                              previousLinkClassName={'page-link'}
                              nextClassName={'page-item'}
                              nextLinkClassName={'page-link'}
                              containerClassName={'pagination'}
                              activeClassName={'active'}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
};