import React       from 'react'
import { Button }  from 'react-bootstrap';

import ChangeStatus     from '../../core/components/grid/ChangeStatus'
import IconGridRoute    from '../../core/components/icon/IconGridRoute'
import IconLabelStatus  from '../../core/components/icon/IconLabelStatus'
import IconLabelTicket  from '../../core/components/icon/IconLabelTicket'
import GridParent       from "../../core/components/grid/GridParent"

export default class Company extends GridParent {
     
   constructor(){
      const titlePage         = "Empresas"
      const apiList       = "/companies/pages"
      const routeForm     = "/company/form" 
      const apiStatus     = "/companies/update/status"

      super(titlePage, apiList, routeForm, apiStatus)
   }

   render() {
      return (
         <>
           {super.render(
               <>
                  <div className="card">
                     <div className="card-body">
                        <div className="row">
                           <div className="col-lg-3">
                              <input type="text" 
                                    name="title" 
                                    className="form-control" 
                                    maxLength="40" 
                                    placeholder="Buscar" 
                                    onChange={this.handleFilter} />           
                           </div>
                           <div className="col-lg-3">
                              <select className="form-control" name="active" onChange={this.handleFilter}>
                                 <option value={2}>Todos</option>
                                 <option value="1">Ativo</option>
                                 <option value="0">Inativo</option>
                              </select>
                           </div>
                           <div className="col-lg-3">
                              <Button className="btn" onClick={this.sendRequest}>
                                 <i className="fas fa-search"></i>
                              </Button>
                           </div>
                        </div>            
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-body">   
                        <div className="table-responsive">
                           <table className="table table-striped table-hover text-nowrap">
                              <thead>
                                 <tr>
                                    <th>Nome</th>
                                    <th>CNPJ</th>
                                    <th>PHONE</th>
                                    <th>HORAS</th>
                                    <th>RENOVAÇÃO</th>
                                    <th>STATUS</th>
                                    <th></th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.data.map(row => 
                                    {
                                        return  <tr key={row.id}>
                                        <td>{row.name}</td>
                                        <td>{row.cnpj}</td>
                                        <td>{row.phone}</td>
                                        <td>{row.hours}</td>
                                        <td>{row.renewal_date}</td>
                                        <td>
                                          <IconLabelStatus value={row.active} />
                                        </td>
                                        <td align="right">
                                           <IconGridRoute url={this.routeForm}  id={row.id} icon="fa-edit" />
                                        </td>
                                     </tr>
                                    }
                                 )}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </>
            )} 
         </>
      );
   }
};