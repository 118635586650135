import React from 'react'
import FormPassword from '../../core/components/form/formForgot';
import { Row, Form, Col, Card } from 'react-bootstrap';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

class ResetPassword extends FormPassword {

    constructor(props) {
        const state = { email: "", password: "", security_code: "" }
        const apiReset = "/password/reset"
        const routeList = {
            success: "/login",
            fail: "/reset_password"
        }
        const context = {
            success: 'Senha atualizada com sucesso!',
            fail: 'Código de segurança inválido!',
            formFail: 'Por favor, preencha todos os campos!',
            title: 'reset'
        }

        super(props, state, apiReset, routeList, context)
    }

    render() {
        return (
            <>
                {
                    super.render(
                        <>
                            <div className='forgot main-panel'>
                                <Card className="text-center card-forgot"
                                style={{width: '40vw', backgroundColor: '#f1fcff', borderRadius: '10px' }}>
                                    <Card.Header className='forgot'>
                                        <h1>Atualização de senha</h1>
                                    </Card.Header>

                                    <Card.Body>
                                        <Card.Title className='forgot'>
                                            <h3>Informe seu email, o código de segurança e a nova senha</h3>
                                        </Card.Title>

                                        <Form.Control style={{ width: '40%', margin: 'auto' }} type="text" placeholder='Email' name="email" defaultValue={this.state.email} onChange={this.handleChange} />
                                        <Form.Control style={{ width: '40%', margin: 'auto', marginTop:'1rem' }} type="password" placeholder='Nova senha' name="password" defaultValue={this.state.password} onChange={this.handleChange} />
                                        <Form.Control style={{ width: '40%', margin: 'auto', marginTop:'1rem' }} type="text" placeholder='Código de segurança' name="security_code" defaultValue={this.state.security_code} onChange={this.handleChange} />

                                        <Row>
                                            <Col>
                                                <div className="submit">
                                                    <input className="submit" value="Confirmar" type="button" onClick={this.handleSubmit} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Card.Footer>
                                        </Card.Footer>
                                    </Card.Body>
                                </Card>
                            </div>
                        </>
                    )
                }
            </>
        )
    }
}

const ResetPasswordComponent = props => {
    const match = {
       params: useParams()
    }
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
 
    return <ResetPassword match={match} navigate={navigate} searchParams={searchParams} {...props} />
 }
 
export default ResetPasswordComponent