import React, {Component} from 'react';

import PageHeader from '../../core/components/pageHeader/PageHeader'

export default class Home extends Component {
	
  render() {

		return (
         <>
            <PageHeader title="CRM ATHAN" />
         </>
		);
	}
};