import {Component} from 'react'

import { logout } from '../../core/components/api/Auth'

export default class Logout extends Component {
   
   constructor(props){
        super()
   }

   logout () {
        logout()
        window.location.href = "/login"
   }
   
   render(){
        return (
            <div>
                {this.logout()}
            </div>
        )
   }
}