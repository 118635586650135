import React from 'react'
import { Row, Form, Col} from 'react-bootstrap';

import FormParent from '../../core/components/form/FormParent'
import IconLabelTicket  from '../../core/components/icon/IconLabelTicket'
import FormComment from '../../core/components/form/formComment'
import { useParams, useNavigate } from 'react-router-dom';


class TicketFormEdit extends FormParent {

   constructor(props) {
      const state = {
         ticket_id: "",
         title: "",
         external_protocol: "00",
         description: "",
         created_by: "",
         responsavel: "",
         status: "",
         title: "",
         files: [],
         files_details: [],
         classification_id: '1',
         used_time: "00:00",
         support_hours: "00:00",
         agentDefault: [],
         showStatus: "hidden",
         showHour: "hidden",
         showResp: "hidden",
         showProtocolo: "hidden",
         showHourSuporte: "hidden"
      }
      const titlePage = "Ticket"
      const apiDefault = "/ticket/read"
      const routeList = "/ticket"

      super(props, state, titlePage, apiDefault, routeList)

      this.company = JSON.parse(localStorage.getItem('@wf-User'))['company_id']
   }

   componentDidMount() {
      this.selectPreview('/user/list', 'agentDefault', 'responsavel', 'user_id')
      this.handleView()
   }


   render() {

      console.log("NEW STATE FILE DETAILS", this.state)
      return (
         <>
            {super.render(
               <div className="card-body">

                     <div className="card" style={{background: '#f7fdff'}}><div className="card-body" style={{ height: '70px'}}>
                        <div className="card-title"><h3> ID: {this.props.match.params.id} - {this.state.title}</h3></div>
                     </div></div>
                  
                  <div className="card-body">
                     <Row>
                        <Col xs={8}>
                           <Form.Control as="textarea" rows={10} name="description" defaultValue={this.state.description} disabled />
                           <h9 className="col-form-label-sm">Ticket criado por {this.state['created.name']} ({this.state['created.email']}) em {this.state.created_at}</h9>
                           <br /><h9 className="col-form-label-sm">Última atualização: {this.state.update_at}</h9>
                        </Col>
                        <Col xs={1}></Col>
                        <Col xs={3}>
                           {!this.company ?
                              <Row>
                              { !this.state.showStatus || this.state.showStatus === "hidden" 
                                    ?  <Col xs={12} onClick={() => this.setState({ showStatus: 'true' })}>
                                          <span className="label label-default"><Form.Label className="label label-default"><IconLabelTicket value={this.state.status} /> {this.state.status} </Form.Label></span>
                                       </Col>
                                    :
                                 <Col xs={12}>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control as="select" name="status" value={this.state.status} onChange={this.handleChange} >
                                       <option value="A FAZER">A FAZER</option>
                                       <option value="EM PROGRESSO">EM PROGRESSO</option>
                                       <option value="NEGOCIAÇÃO">NEGOCIAÇÃO</option>
                                       <option value="CLIENTE">CLIENTE</option>
                                       <option value="CONCLUÍDO">CONCLUÍDO</option>
                                       <option value="BLOQUEADO">BLOQUEADO</option>
                                    </Form.Control>
                                 </Col>
                                 }
                              </Row>

                           :
                              <Row>
                                 <Col xs={12}>
                                          <span className="label label-default"><Form.Label className="label label-default"><IconLabelTicket value={this.state.status} /> {this.state.status} </Form.Label></span>
                                 </Col>
                              </Row>
                           }
                           <br />
                           <Row>
                              {!this.company && this.state.showHour === "true" ?
                              <Col xs={12}>
                                 <Form.Control type="time" name="used_time" value={this.state.used_time} onChange={this.handleChange} />
                              </Col>
                              :
                              <Col xs={12} onClick={() => this.setState({showHour: 'true'})}>
                                 <Form.Label><i className="fas fa-clock"></i> {this.state.used_time} - Dev</Form.Label>
                              </Col>
                              }
                           </Row>
                           <Row>
                              {!this.company && this.state.showHourSuporte === "true" ?
                              <Col xs={12}>
                                 <Form.Control type="time" name="support_hours" value={this.state.support_hours} onChange={this.handleChange} />
                              </Col>
                              :
                              <Col xs={12} onClick={() => this.setState({showHourSuporte: 'true'})}>
                                 <Form.Label><i className="fas fa-clock"></i> {this.state.support_hours} - Suporte</Form.Label>
                              </Col>
                              }
                           </Row>
                           <br />
                           <Row>
                              
                                 {this.state.showProtocolo === "true" ?
                                    <Col xs={12}>
                                       <Form.Control type="text" name="external_protocol" defaultValue={this.state.external_protocol} onChange={this.handleChange} />
                                    </Col>
                                    :
                                    <Col xs={12} onClick={() => this.setState({showProtocolo: 'true'})}>
                                       <Form.Label>Protocolo: {this.state.external_protocol} </Form.Label>
                                    </Col>
                                 }
                           </Row>
                           <br />
                           <Row>
                              <Col xs={12}>
                                 <h1><Form.Label style={{color: '#344675'}}>PARTICIPANTES</Form.Label></h1>
                              </Col>
                           </Row>
                           <Row>
                              <Col xs={12}>
                                 <Form.Label><i className="fas fa-user-circle fa-lg"></i> {this.state['created.name']} ({this.state['created.email']})</Form.Label>
                              </Col>
                           </Row>
                           <br />
                           <Row>
                           {!this.company && this.state.showResp === "true" ?
                              <Col xs={12}>
                                 <Form.Control as="select" name="responsavel" value={this.state.responsavel} placeholder={this.state.responsavel} onChange={this.handleChange} >
                                    <option value="">Selecione um agente</option>
                                       {this.state.agentDefault.map(respons => {
                                          return <option key={respons.user_id} value={respons.user_id}>{respons.name} ({respons.email})</option>
                                       })}
                                 </Form.Control>
                              </Col>
                              :
                              <Col xs={12} onClick={() => this.setState({showResp: 'true'})}>
                                 <Form.Label><i className="fas fa-user-circle fa-lg"></i> {this.state['resp.name']} ({this.state['resp.email']})</Form.Label>
                              </Col>
                              }
                           </Row>
                           <br />
                           <Row>
                              <Col xs={12}>
                                 <Form.Label><i className="far fa-building fa-lg"></i> {this.state['ct.company.name']}</Form.Label>
                              </Col>
                           </Row>
                        </Col>
                     </Row>
                  </div>
                  <div className="card-body">
                     <Row>
                        <Col xs={12}>
                           <h1>
                              <Form.Label>EVIDÊNCIAS</Form.Label>
                           </h1>
                           <Row>
                              <Col xs={4}>
                                   <div className="drop-container">
                                     <Form.Label><i className="fas fa-upload"></i> Anexar</Form.Label>
                                     <Form.Control type="file" name="files_details" defaultValue={this.state.files_details} onChange={this.handleUpload}></Form.Control>
                                   </div>
                              </Col>
                              <Col xs={6}>
                                 {this.state.files_details.map((image, index) =>
                                     <Row>
                                       <Form.Label key={index}><a href={image.detail_drive_url}>{image.detail_name}</a></Form.Label>
                                     </Row>
                                 )}
                                 <div></div>
                                 {this.state.files.map((arquivo, index) =>
                                    <Row>
                                       <Form.Label key={index}><a href={arquivo.file_drive_url}>{arquivo.file_name}</a></Form.Label>
                                    </Row>
                                 )}
                              </Col>
                           </Row>
                        </Col>
                     </Row>
                  </div>
                  <div className="card-body">
                     <Row>
                        <Col xs={4}>
                           <h1>
                              <Form.Label>COMENTÁRIOS</Form.Label>
                           </h1>
                        </Col>
                     </Row>
                  </div>

                  <FormComment state={this.state} />


               </div>
            )}
         </>
      )
   }
}

const TicketEditComponent = props => {
   const match = {
      params: useParams()
   }
   const navigate = useNavigate()

   return <TicketFormEdit match={match} navigate={navigate} {...props} />
}

export default TicketEditComponent