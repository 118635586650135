import React, {Component} from 'react';
import { useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2'

import customApi    from "../api/CustomApi"
import notification from "../notification/Notification"

class InactiveItem extends Component {

    inactiveItem = () => {

      console.log(this.props) 
      
      let api = `${this.props.url}/${this.props.id}`;
      
      Swal.fire({title: "Deseja remover? Este item será excluído e esse processo não poderá ser revertido.", showCancelButton: true})
          .then((result) => {

            console.log("endereço de API:", api);

            if (result.value)
                customApi.delete(api, {}, (response) => {
                    notification.success("Removido com sucesso")
                    this.props.navigate(0)
                });
        });
    }  
 
    render() {

        return (
          <a onClick={this.inactiveItem} className="link_grid">
              <i className="fas fa-trash-alt"></i>
          </a>
        );
    }
};


const InactiveItemComponent = props => {
    const navigate = useNavigate()
  
    return <InactiveItem navigate={navigate} {...props} />
 }
 
export default InactiveItemComponent