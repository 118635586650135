import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import {Row, Form, Col } from 'react-bootstrap';

import FormParent from '../../core/components/form/FormParent'

class CustomerFormEdit extends FormParent {
   
   constructor(props){
      const state = {
         active: '',
         address: '',
         birth: '',
         category: '',
         cep: '',
         city: '',
         company_id: '',
         country: '',
         cpf: '',
         customer_id: '',
         email: '',
         name: '',
         note: '',
         owner_id: '',
         phone: '',
         state: '',
         tenant_id: ''
      }
      const titlePage     = "Editar Cliente";
      const apiDefault    = "/customer/read/id";
      const routeList     = "/customer";

      super(props, state, titlePage, apiDefault, routeList)
   }

   render() {
      return(
         <>
            {super.render(
               <div className="card-body">
                    <div className="row">                       
                     
                     <div className="card-body">
                        <Row>
                           <Col xs={4}>
                           <h5>DADOS PESSOAIS</h5>
                           </Col></Row>
                        <br />
                        <Row>
                           <Col xs={4}>
                              <Form.Label>Nome:</Form.Label>
                              <Form.Control type="text" name="name" defaultValue={this.state.name || ''} onChange={this.handleChange} />
                           </Col>
                           <Col xs={2}>
                              <Form.Label>CPF</Form.Label>
                              <Form.Control type="text" name="cpf" defaultValue={this.state.cpf || '' } onChange={this.handleChange}/>
                           </Col>
                           <Col xs={2}>
                              <Form.Label>D.N.</Form.Label>
                              <Form.Control type="date" name="birth" defaultValue={this.state.birth || ''} onChange={this.handleChange} />
                           </Col>
                        </Row>
                        <br />
                        <Row>
                           <Col xs={4}>
                           <h5>DADOS DE CONTATO</h5>
                           </Col></Row>
                        <br />
                        <Row>
                           <Col xs={2}>
                              <Form.Label>Telefone</Form.Label>
                              <Form.Control type="tel" name="phone" defaultValue={this.state.phone || ''} onChange={this.handleChange} />
                           </Col>
                           <Col xs={4}>
                              <Form.Label>E-mail</Form.Label>
                              <Form.Control type="email" name="email" defaultValue={this.state.email || ''} onChange={this.handleChange} />
                           </Col>
                           <Col xs={2}>
                              <Form.Label>CEP</Form.Label>
                              <Form.Control type="text" name="cep" defaultValue={this.state.cep || ''} onChange={this.handleChange} />
                           </Col>
                           <Col xs={2}>
                              <Form.Label>Número</Form.Label>
                              <Form.Control type="text" name="numero" defaultValue="" onChange={this.handleChange} />
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={4}>
                              <Form.Label>Endereço</Form.Label>
                              <Form.Control type="text" name="address" defaultValue={this.state.address || ''} onChange={this.handleChange} />
                           </Col>
                           <Col xs={2}>
                              <Form.Label>Cidade</Form.Label>
                              <Form.Control type="text" name="city" defaultValue={this.state.city || ''} onChange={this.handleChange} />
                           </Col>
                           <Col xs={2}>
                              <Form.Label>Estado</Form.Label>
                              <Form.Control type="text" name="state" defaultValue={this.state.state || ''} onChange={this.handleChange} />
                           </Col>
                           <Col xs={2}>
                              <Form.Label>País</Form.Label>
                              <Form.Control type="text" name="country" defaultValue={this.state.country || ''} onChange={this.handleChange} />
                           </Col>
                        </Row>
                        <br />
                        <Row>
                           <Col xs={4}>
                           <h5>DETALHES</h5>
                           </Col>
                        </Row>
                        <br/>
                        <Row>
                           <Col xs={2}>
                              <Form.Label>Categoria</Form.Label>
                              <Form.Control type="text" name="category" defaultValue={this.state.category || ''} onChange={this.handleChange} />
                           </Col>
                        </Row> 
                        <Row>
                           <Col xs={6}>
                              <Form.Label>Anotações</Form.Label>
                              <Form.Control as="textarea" rows={5} style={{ resize: 'none' }} name="notes" defaultValue={this.state.note || ''} onChange={this.handleChange} />
                           </Col>
                        </Row>
                     </div> 
                  </div>
               </div>
            )}
         </>
      )
   }
}

const CustomerFormEditComponent = props => {
   const match = { params: useParams() };
   const navigate = useNavigate();
   
   return <CustomerFormEdit match={match} navigate={navigate} {...props} />
}

export default CustomerFormEditComponent;