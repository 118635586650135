import React       from 'react'
import { Button }  from 'react-bootstrap';

import ChangeStatus     from '../../core/components/grid/ChangeStatus'
import IconGridRoute    from '../../core/components/icon/IconGridRoute'
import IconLabelStatus  from '../../core/components/icon/IconLabelStatus'
import GridParent       from "../../core/components/grid/GridParent"

export default class Customer extends GridParent {
     
   constructor(){
      const titlePage  = "Clientes"
      const apiList    = "/customer/pages"
      const routeForm  = "/customer/form"
      const apiStatus  = "/customer/update/status"    
      const routeView  = "/customer/view"  

      //let dados      = this.state.data.user

      super(titlePage, apiList, routeForm, apiStatus, routeView)
   }

   render() {

      return (
         <>
           {super.render(
               <>
                  <div className="card">
                     <div className="card-body">
                        <div className="row">
                           <div className="col-lg-3">
                              <input type="text" 
                                    name="name" 
                                    className="form-control" 
                                    maxLength="40" 
                                    placeholder="Buscar" 
                                    onChange={this.handleFilter} />           
                           </div>
                           <div className="col-lg-3">
                              <select className="form-control" name="active" onChange={this.handleFilter}>
                                 <option value={2}>Todos</option>
                                 <option value={1}>Ativos</option>
                                 <option value={0}>Desativados</option>
                              </select>
                           </div>   
                           <div className="col-lg-3">
                              <Button className="btn" onClick={this.sendRequest}>
                                 <i className="fas fa-search"></i>
                              </Button>
                           </div>
                        </div>            
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-body">   
                        <div className="table-responsive">
                           <table className="table table-striped table-hover text-nowrap">
                              <thead>
                                 <tr>
                                    <th>CPF</th>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <th>Telefone</th>
                                    <th>Fidelidade</th>
                                    <th></th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.data.map(row => 
                                    <tr key={row.customer_id}>
                                       <td>{row.cpf}</td>
                                       <td>{row.name}</td>
                                       <td>{row.email}</td>
                                       <td>{row.phone}</td>
                                       <td>
                                          <IconLabelStatus value={row.active} />
                                       </td>
                                       <td align="right">
                                          <IconGridRoute url={this.routeView}  id={row.customer_id} icon="fas fa-search" />
                                          <IconGridRoute url={this.routeForm}  id={row.customer_id} icon="fa-edit" />                  

                                          <ChangeStatus url={this.apiStatus} id={row.customer_id} />
                                       </td>
                                    </tr>
                                    
                                 )}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </>
            )} 
         </>
      );
   }
};