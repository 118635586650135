import React, {Component}         from 'react';
import {BrowserRouter}            from "react-router-dom";
import { ToastContainer }         from 'react-toastify';
import { localStoragePersonalized } from './core/components/api/Auth';

import Header from './core/template/header/Header';
import Menu   from './core/template/menu/Menu';
import Routeres from './core/template/menu/MenuRoutes';
import MenuAgent   from './core/template/menu/MenuAgents';
import RouteresAgent from './core/template/menu/MenuRoutesAgent';
import MenuRouteres from './core/template/login/loginRoutes';

import SignIn from './modules/signin/login';
import ForgotPassword from './modules/signin/forgot-password';

import "./App.css"
import "./core/components/form/form.css"

import { isAuthenticated } from './core/components/api/Auth';

export default class App extends Component {
  state = {
    forgot_passwod: false
  }

  constructor(){
    super()
    this.setState = this.setState.bind(this);
    this.token = isAuthenticated()
    this.agent = localStoragePersonalized.getUserId
    this.tenant = localStoragePersonalized.getTenantId
  }
    
  render() {

    if(!this.token || this.tenant === 6) {
      return (
        <BrowserRouter>
            <ToastContainer/>
            <MenuRouteres/>
        </BrowserRouter>
      )
    }
    else if(this.agent) {
      return (
        <BrowserRouter>
          <ToastContainer />
          <MenuAgent />
          <div className="main-panel">
            <Header />
            <div className="content">
              <RouteresAgent />
            </div>
          </div>
        </BrowserRouter>
      );
    }

		return (
         <BrowserRouter>
          <ToastContainer />
            <Menu />
            
			      <div className="main-panel">
              <Header />
				      <div className="content">
                <Routeres/>	
              </div>
            </div>
         </BrowserRouter>
		);
	}
};