import React       from 'react'
import { Button }  from 'react-bootstrap';

import ChangeStatus     from '../../core/components/grid/ChangeStatus'
import IconGridRoute    from '../../core/components/icon/IconGridRoute'
import IconLabelStatus  from '../../core/components/icon/IconLabelStatus'
import GridParent       from "../../core/components/grid/GridParent"

import { localStoragePersonalized } from '../../core/components/api/Auth';

export default class Tenant extends GridParent {
     
   constructor(){
      const titlePage     = "Tenants"
      const apiList       = "/tenant/pages"
      const routeForm     = "/tenant/form" 
      const apiStatus     = "/tenant/update/status"

      super(titlePage, apiList, routeForm, apiStatus)

      this.master = localStoragePersonalized.getMasterId
   }

   render() {

    if(this.master){
      return (
         <>
           {super.render(
               <>
                  <div className="card">
                     <div className="card-body">
                        <div className="row">
                           <div className="col-lg-3">
                              <input type="text" 
                                    name="name" 
                                    className="form-control" 
                                    maxLength="40" 
                                    placeholder="Buscar" 
                                    onChange={this.handleFilter} />           
                           </div>
                           <div className="col-lg-3">
                              <select className="form-control" name="active" onChange={this.handleFilter}>
                                 <option value={2}>Todos</option>
                                 <option value={1}>Ativos</option>
                                 <option value={0}>Desativados</option>
                              </select>
                           </div>   
                           <div className="col-lg-3">
                              <Button className="btn" onClick={this.sendRequest}>
                                 <i className="fas fa-search"></i>
                              </Button>
                           </div>
                        </div>            
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-body">   
                        <div className="table-responsive">
                           <table className="table table-striped table-hover text-nowrap">
                              <thead>
                                 <tr>
                                    <th>Nome</th>
                                    <th>DB IP</th>
                                    <th>DB Port</th>
                                    <th>Licenças</th>
                                    <th>Status</th>
                                    <th></th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.data.map(row => 
                                    <tr key={row.tenant_id}>
                                       <td>{row.tenant_name}</td>
                                       <td>{row.db_ip}</td>
                                       <td>{row.db_port}</td>
                                       <td>{row.max_users}</td>
                                       <td>
                                          <IconLabelStatus value={row.active} />
                                       </td>
                                       <td align="right">
                                          <IconGridRoute url={this.routeForm}  id={row.tenant_id} icon="fa-edit" />

                                          <ChangeStatus url={this.apiStatus} id={row.tenant_id} />
                                       </td>
                                    </tr>
                                 )}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </>
            )} 
         </>
      );}
      return (
      <></>
      )
   }
};