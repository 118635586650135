import React from 'react'
import { Row, Form, Col, Table } from 'react-bootstrap';

import ViewParent from '../../core/components/view/ViewParent'

import { useParams, useNavigate } from 'react-router-dom';


class ContactView extends ViewParent {

    constructor(props) {
        const state = {
            contact_id: '',
            channel: '',
            agent: '',
            customer: '',
            twilio_sid: '',
            transcript: '',
            call_details: '',
            call_url_voice: '',
            conversation_sid: '',
            notes: '',
            ticket_id: '',
            active: '',
            "user.name": '',
            "user.email": '',
            "client.name": '',
            "client.email": ''
        }
        const titlePage = "Atendimento"
        const apiDefault = "/contact/read"
        const routeList = "/atendimentos"

        super(props, state, titlePage, apiDefault, routeList)
    }


    render() {
        return (
            <>
                {super.render(
                    <div className="card-body">

                        <div className="card-body">
                            <Row>
                                <Col>
                                    <h1>
                                        <Form.Label>PARTICIPANTES</Form.Label>
                                    </h1>
                                </Col>
                            </Row>
                            <Table striped >
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Cliente</th>
                                        <th>Agente</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr bordered={false}>
                                        <td>Nome</td>
                                        <td>{this.state['client.name']}</td>
                                        <td>{this.state['user.name']}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{this.state['client.email']}</td>
                                        <td>{this.state['user.email']}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Row>
                                <Col>
                                    <h1>
                                        <Form.Label>CARACTERÍSTICAS</Form.Label>
                                    </h1>
                                </Col>
                            </Row>
                            <Table striped>

                                <tbody>
                                    <tr>
                                        <td>IDENTIFICADOR: {this.state.contact_id || 'Sem detalhes!'}</td>
                                    </tr>
                                    <tr>
                                        <td>CANAL: {this.state.channel.toUpperCase() || 'Sem detalhes!'}</td>
                                    </tr>
                                    <tr>
                                        <td>TWILIO SID: {this.state.twilio_sid || 'Sem detalhes!'}</td>
                                    </tr>
                                    <tr>
                                        <td>TRANSCRIÇÃO: {this.state.transcript || 'Sem detalhes!'}</td>
                                    </tr>
                                    <tr>
                                        <td>DETALHES DO CONTATO: {this.state.call_details || 'Sem detalhes!'}</td>
                                    </tr>
                                    <tr>
                                        <td>LINK DO CONTATO: {this.state.call_url_voice || 'Sem detalhes!'}</td>
                                    </tr>
                                    <tr>
                                        <td>CONTATO SID: {this.state.conversation_sid || 'Sem detalhes!'}</td>
                                    </tr>
                                    <tr>
                                        <td>NOTAS: {this.state.notes || 'Sem detalhes!'}</td>
                                    </tr>
                                    <tr>
                                        <td>STATUS: {this.state.active ? 'ATIVO' : 'DESATIVADO'}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                    </div>
                )}
            </>
        )
    }
}

const TicketEditComponent = props => {
    const match = {
        params: useParams()
    }
    const navigate = useNavigate()

    return <ContactView match={match} navigate={navigate} {...props} />
}

export default TicketEditComponent