import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./notification.css"

const DEFAULT = {
    position: "bottom-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    className: 'foo-bar'
};

const ATHAN = {
    position: "bottom-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    className: 'notification-default'
};

class Notification {

    success = (msg) => toast.success(msg, DEFAULT);
    
    error   = (msg) => toast.error(msg, ATHAN);

    warn    = (msg) => toast.warn(msg, ATHAN);

    info    = (msg) => toast.info(msg, ATHAN);
}

const notification = new Notification();

export default notification;
