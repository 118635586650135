import React from 'react'
import { Button } from 'react-bootstrap';

import ChangeStatus from '../../core/components/grid/ChangeStatus'
import InactiveItem from '../../core/components/grid/InactiveItem'
import IconGridRoute from '../../core/components/icon/IconGridRoute'
import IconLabelStatus from '../../core/components/icon/IconLabelStatus'
import GridParent from "../../core/components/grid/GridUsers"
import { localStoragePersonalized } from '../../core/components/api/Auth';


export default class User extends GridParent {

   constructor() {
      const titlePage = "Usuários"
      const apiList = {
         agentes: "/user/pages",
         admins: "/admin/pages",
         tenant: "/tenant/list"
      }
      const master       = localStoragePersonalized.getMasterId
      const routeForm    = "/user/form"
      const apiStatus    = "/user/update/status"
      const inactiveItem = "/user/delete"
      const keyWord      = 'tenant'

      super(titlePage, apiList, routeForm, apiStatus, inactiveItem, master, keyWord)
   }

   render() {

      console.log(this.state.filter)

      return (
         <>
            {super.render(
               <>
                  <div className="card">
                     <div className="card-body">
                        <div className="row">
                           {
                              this.master ? (
                                 <div className="col-lg-3">
                                    <select className="form-control" name="select" onChange={this.handleState}>
                                       <option value={0}>Todos os Tenant</option>
                                       {
                                          this.state.tenant.map((value, i) => {
                                             return <option key={i} value={value.tenant_id}> {value.tenant_name} </option>
                                          })
                                       }
                                    </select>
                                 </div>
                              ) : (<div>  </div>)
                           }
                           <div className="col-lg-3">
                              <select className="form-control" name="user" onChange={this.handleState}>
                                 <option value="agentes">Agentes</option>
                                 <option value="admins">Admins</option>
                              </select>
                           </div>
                           <div className="col-lg-3">
                              <select className="form-control" name="active" onChange={this.handleFilter}>
                                 <option value={2}>Todos</option>
                                 <option value={1}>Ativos</option>
                                 <option value={0}>Desativados</option>
                              </select>
                           </div>
                           <div className="col-lg-3">
                              <Button className="btn" onClick={this.sendRequest}>
                                 <i className="fas fa-search"></i>
                              </Button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-body">
                        <div className="table-responsive">
                           <table className="table table-striped table-hover text-nowrap">
                              <thead>
                                 <tr>
                                    <th>Nome</th>

                                    <th>Email</th>
                                    <th>Data de criação</th>
                                    <th>Tenant</th>
                                    <th>Status</th>
                                    <th></th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.data.map((row, i) =>

                                    <tr key={i}>
                                       <td>{row.name}</td>
                                       <td>{row.email}</td>
                                       <td>{new Date(row.created_at).toLocaleString()}</td>
                                       <td>{row.tenant_name}</td>
                                       <td>
                                          <IconLabelStatus value={row.active} />
                                       </td>
                                       <td align="right">
                                          <IconGridRoute url={this.routeForm} id={row.user_id} icon="fa-edit" />

                                          <ChangeStatus url={this.apiStatus} id={row.user_id} />
                                          <InactiveItem url={this.inactiveItem} id={row.user_id} />

                                       </td>
                                    </tr>

                                 )}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </>
            )}
         </>
      );
   }
};