
class DisplayLoader{

    show(){
        var myElement = document.querySelector(".loader");

        myElement.style.display = "block";
    }

    hide(){
        var myElement = document.querySelector(".loader");

        myElement.style.display = "none";
    }
}

const display = new DisplayLoader();

export default display;