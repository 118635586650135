import React, {Component}        from 'react'
import {Row, Form, Col, Button } from 'react-bootstrap';

import customApi     from '../api/CustomApi'
import PageHeader    from '../pageHeader/PageHeader'
import notification  from "../notification/Notification"

import "./form.css"

export default class FormParent extends Component {
 
   constructor(props, initialState, titlePage, apiDefault, routeList, iconTitle){
      
      super(props)

      this.initialState = initialState;
      this.titlePage    = titlePage;
      this.apiDefault   = apiDefault;
      this.routeList    = routeList;

      this.state        = initialState;

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleUpload = this.handleUpload.bind(this);
   }
   
   componentDidMount() {      
      this.handleView()
   } 

   /**
    * @description Recupera dados para formulário do tipo select
    * @param {string} api URL da api
    * @param {string} field variável que irá receber uma lista de objetos
    * @param {string} defaultValue variável responsável pelo defaultValue
    * @param {string} value campo do retorno que contem o valor desejado
    */
   selectPreview(api, field, defaultValue = '', value = '') {
      customApi.get(`${api}`, {}, (response) => {
         
         this.setState({[field]: response.data})
         
         if(defaultValue){
            this.setState({[defaultValue]: response.data[0][value]})
         }
         
      })
   }

   /**
    * Remove campos do state que não são esperados no servidor
    */
   formStateValidation() {
      let payload = false

      switch (this.titlePage) {
         case 'Ticket':
            payload = {...this.state}
            delete payload.agentDefault
            delete payload.costumerDefault
            delete payload.comments
            delete payload.ticket_id
            delete payload.active
            delete payload.created_at
            delete payload.files_details
            console.log("TICKET_PAGE:", payload)
            break;

         case 'Usuário':
            payload = {...this.state}
            this.apiDefault = `/${this.state.profile}/create`
            delete payload.tenantDefault
            delete payload.profile
            delete payload.companyDefault
            delete payload.showStatus
            delete payload.showHour
            delete payload.showResp
            delete payload.showProtocolo
            delete payload.showHourSuporte
            console.log("USER_PAGE:", payload)
            break;

         case 'Cliente':
            payload = {...this.state}
            delete payload.tenantDefault
            delete payload.companyDefault
            delete payload.showStatus
            delete payload.showHour
            delete payload.showResp
            delete payload.showProtocolo
            delete payload.showHourSuporte
            console.log("CLIENTE_PAGE:", payload)
            break;  

         default:
            break;
      }

      console.log("🚀 ~ file: FormParent.js:88 ~ FormParent ~ formStateValidation ~ payload", payload)
      return payload || this.state;
   }

   /**
    * Upload de arquivos para evidências e profile do usuário
    */
    handleUpload(event) {

      const upload = event.target.files[0]
      const name   = event.target.name;
      const folder = this.routeList.substring(1);

      const newState = {...this.state}

      let formData = new FormData();
      formData.append('folder', folder);
      formData.append('file', upload)

      customApi.post(`${process.env.REACT_APP_API_URL}/upload`, formData, (response) => {
        console.log("UPLOAD", response);

        let file_name = [{
          detail_name: response.data.file_name,
          detail_drive_url: response.data.file_drive_url
        }]

        if(this.state.ticket_id){
          customApi.post(`${process.env.REACT_APP_API_URL}/ticket/insert-file/${this.state.ticket_id}`, {files: [response.data.file_id]}, (filesResponse) => {
            console.log("ASSOCIATE FILE TO TICKET", filesResponse);

            this.setState({[name]: file_name});

            notification.success("Upload realizado com sucesso! 👍");
          })
        }

        console.log("FILE_NAME", file_name);

       this.setState({[name]: this.state.files.concat([response.data.file_id]), files_details: file_name}) 

      })
    }

   /**
    * Realiza a visualização dos dados
    */
   handleView(){
      let id = ""
    
      try {
         id = this.props.match.params.id
      } catch (error){
         console.log("🚀 ~ file: FormParent.js ~ line 42 ~ FormParent ~ handleView ~ error", error.message)
      }

      if(id){
         customApi.get(`${this.apiDefault}/${id}`, {}, (response) => this.setState(response.data))
      }  
   }

   /**
    * Realiza a renderização do field do formulario
    * 
    * @param {*} event 
    */
   handleChange(event) {
      const name  = event.target.name; 
      const value = event.target.value;
      this.setState({[name]: value, showStatus: 'hidden', showHourSuporte: 'hidden', showHour: 'hidden', showResp: 'hidden', showProtocolo: 'hidden'})
   }

   /**
    * Realiza o submit do formulario
    * 
    * @param {*} event 
    */
   handleSubmit(event) {

      event.preventDefault();

      const payload = this.formStateValidation()
      
      let id = ""
    
      try {
          id = this.props.match.params.id
      } catch (error){
         console.log("🚀 ~ file: FormParent.js ~ line 89 ~ FormParent ~ handleSubmit ~ error", error.message)
      }

      if(id){
         
         customApi.put(`${this.routeList}/update/${id}`, payload, (response) => {

            notification.success("Alteração realizada com sucesso! 👍")

            this.setState(this.initialState);
            
            this.props.navigate(this.routeList)
         });
      }
      else{
         customApi.post(this.apiDefault, payload, (response) => {
            
            notification.success("Cadastro realizado com sucesso! 👍")

            this.setState(response.data);

            this.props.navigate(this.routeList);
         });
      }
   }
 
   render(formContent) {

		return(
         <>
            <PageHeader title={this.titlePage} routeList={this.routeList} />

            <Form onSubmit={this.handleSubmit}>


               <div className="card">
                  
                  {formContent}
                  
                  <div className="card-footer">
                     <Row>
                        <Col xs={12} className="text-right">
                           <Button type="submit" className="btn">
                              <i className="fas fa-save"></i> Salvar
                           </Button>
                        </Col>
                     </Row>
                  </div>
               </div>
            </Form>
         </>
      )
   }
}