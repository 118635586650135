import React from 'react'
import { Card } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import FormLogin from '../../core/components/form/formLogin'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

class SignIn extends FormLogin {

   constructor(props) {
      const state = { email: "", password: "", user: "Agente" }
      const redirect = '/login/redirect'
      super(props, state, redirect)
   }

   render() {
      return (
         <>
            {
               super.render(
                  <>
                     {<div className='Signin main-panel'>
                     <Card style={{ width: '40vw', backgroundColor: '#f1fcff', borderRadius: '10px' }}
                     >
                        <div className="login-container" style={{ margin: '2rem' }}>
                           <h2>Bem vindo ao CRM da Athan.cc!</h2>
                           <h1>Login</h1>

                           <form onSubmit={this.handleSubmit}>
                              <div className="login-fields">
                                 <span>
                                    <input placeholder="Email" name="email" defaultValue={this.state.email} type="text" onChange={this.handleChange} />
                                 </span>
                                 <br />
                                 <span>
                                    <input placeholder="Senha" name="password" defaultValue={this.state.password} type="password" onChange={this.handleChange} />
                                 </span>
                              </div>
                              <div className="submit">
                                 <input className="submit" value="Confirmar" type="button" onClick={this.handleSubmit} />
                              </div>
                              <div className='forgot-label' onClick={this.forgotOnClik}>
                                 <Link to='/forgot_password' className='forgot-link'> Esqueceu a senha?</Link>
                              </div>

                           </form>
                           
                        </div>
                        </Card>
                     </div>}
                  </>
               )
            }
         </>
      )
   }
}


const LoginComponent = props => {
   const match = {
      params: useParams()
   }
   const navigate = useNavigate()

   const [searchParams] = useSearchParams();

   return <SignIn match={match} navigate={navigate} searchParams={searchParams} {...props} />
}

export default LoginComponent