import React, { Component } from 'react'
import ReactPaginate from 'react-paginate'
import customApi from "../api/CustomApi"
import PageHeader from '../pageHeader/PageHeader'

import "./grid.css"

export default class GridParent extends Component {

   state = {
      user: 'agentes',
      select: 0,
      tenant: [],
      data: [],
      filter: {
         active: 2
      },
      offset: 1,
      pagination: { limit: 10, offset: 1, total: 0, pages: 0, page: 0 }
   };

   constructor(title, apiList, routeForm, apiStatus, inactiveItem, master, keyWord) {

    console.log(inactiveItem);

      super();

      this.title = title;
      this.apiList = apiList;
      this.routeForm = routeForm;
      this.apiStatus = apiStatus;
      this.inactiveItem = inactiveItem;
      this.master = master
      this.keyWord = keyWord
   }

   componentDidMount() {

    console.log(this.inactiveItem);

      if (this.master) {
         this.sendPersonalizeRequest()
      }
      this.sendRequest();
   }

   /**
    * Mensagem padrao de paginação de resultados
    * 
    * @returns message
    */
   getMessagePagination = () => {
      const obj = this.state.pagination;

      return obj.total > 0 ? `Total de ${obj.total} registros encontrados.` : "Nenhum registro encontrado.";
   }

   /**
     * Processamento dos filtros do grid.
     * 
     * @param event
     */
   handleFilter = (event) => {
      this.setState({ filter: { [event.target.name]: event.target.value } })
   };

   /**
    * Processamento dos filtros do grid.
    * 
    * @param event
    */
   handleState = (event) => {
      this.setState({ [event.target.name]: event.target.value })
   };

   /**
     * Processamento dos filtros do grid a partir da paginação
     * 
     * @param data
     */
   handlePageClick = (data) => this.sendRequest(data)


   requestFormatted = (data) => {
      /** Formatação de parametros */
      const filterByTenant = parseInt(this.state.select) === 0 ? '' : `/${this.keyWord}/${this.state.select}`

      /** Formatação dos filtros em querys */
      const parameter = Object.getOwnPropertyNames(this.state.filter)

      const querys = parameter.map(value => `&${value}=${this.state.filter[value]}`).join('')
      /** Formatação da paginação e adição das querys */
      const page = data && data.selected ? Math.ceil(data.selected) : 0
      let pagination = `?page=${page}&pageSize=${this.state.pagination.limit}` + querys;

      return {
         pagination: filterByTenant + pagination
      }
   }


   /**
    * Processamento da execução do request
    * 
    * @param data
    */
   sendRequest = (data) => {
      const payload = this.requestFormatted(data)

      customApi.get(this.apiList[this.state.user] + payload.pagination, {}, (response) => {
         this.setState({
            data: response.data.rows,
            pagination: response.data.pagination
         });
      });
   };

   /**
 * Request para obter os tenants da base de dados
 * 
 * @param data
 */
   sendPersonalizeRequest = () => {
      customApi.get(this.apiList[this.keyWord], {}, (response) => {
         console.log("🚀 ~ file: GridUsers.js:100 ~ GridParent ~ customApi.get ~ response", response)
         this.setState({ tenant: response.data });
      });
   }

   render(gridContent) {

      return (
         <>
            <PageHeader title={this.title} routeForm={this.routeForm} />

            {gridContent}

            <div className="card-footer">
               <div className="container_pagination">
                  <div className="items">
                     <div className="row">
                        <div className="col-12"><p>{this.getMessagePagination()}</p></div>
                     </div>
                     <div className="row">
                        <div className="col-12">
                           <ReactPaginate
                              previousLabel={'Anterior'}
                              nextLabel={'Próxima'}
                              breakLabel={'...'}
                              pageCount={this.state.pagination.pages}
                              marginPagesDisplayed={5}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              breakClassName={'page-item'}
                              breakLinkClassName={'page-link'}
                              pageClassName={'page-item'}
                              pageLinkClassName={'page-link'}
                              previousClassName={'page-item'}
                              previousLinkClassName={'page-link'}
                              nextClassName={'page-item'}
                              nextLinkClassName={'page-link'}
                              containerClassName={'pagination'}
                              activeClassName={'active'}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
};