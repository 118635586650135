import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import {Row, Form, Col } from 'react-bootstrap';

import FormParent from '../../core/components/form/FormParent'

import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

class CompanyForm extends FormParent {
   
   constructor(props){
      const state = {
         name: '',
         cnpj: '',
         phone: '',
         segment: '',
         tenant_id: ''
      }
      const titlePage         = "Criar Empresa";
      const apiDefault    = "/companies/read/id";
      const routeList     = "/company";

      super(props, state, titlePage, apiDefault, routeList)
   }

   render() {
      return (
         <>
            {super.render(
               <div className="card-body">
                  <Row>
                     <Col xs={4}>
                        <h1>
                           <Form.Label>DADOS DA EMPRESA</Form.Label>
                        </h1>
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={4}>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control type="text" name="name" defaultValue={this.state.name} onChange={this.handleChange} />
                     </Col>
                     <Col xs={2}>
                        <Form.Label>CNPJ</Form.Label>
                        <Form.Control type="number" name="cnpj" defaultValue={this.state.cnpj} onChange={this.handleChange} />
                     </Col>
                     <Col xs={2}>
                        <Form.Label>Telefone</Form.Label>
                        <Form.Control type="phone" name="phone" defaultValue={this.state.phone} onChange={this.handleChange} />
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={4}>
                        <Form.Label>Segmento</Form.Label>
                        <Form.Control type="text" name="segment" defaultValue={this.state.segment} onChange={this.handleChange} />
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={4}>
                        <h1>
                           <Form.Label style={{color: '#344675'}}>DADOS TÉCNICOS</Form.Label>
                        </h1> 
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={2}>
                        <Form.Label>Data Renovação</Form.Label>
                        <Form.Control type="date" name="renewal_date" defaultValue={this.state.renewal_date} onChange={this.handleChange} />
                     </Col>
                     <Col xs={2}>
                        <Form.Label>Horas - Mês</Form.Label>
                        <Form.Control type="time" name="hour" defaultValue={this.state.hour} onChange={this.handleChange} />
                     </Col>
                  </Row>
               </div>
            )}
         </>
      )
   }
}

const CompanyFormComponent = props => {
   const match = { params: useParams() };
   const navigate = useNavigate();
   
   return <CompanyForm match={match} navigate={navigate} {...props} />
}

export default CompanyFormComponent;