import React, {Component} from 'react'
import customApi     from '../api/CustomApi'
import notification  from "../notification/Notification"
import { login }         from '../api/Auth'

import "./form.css"

export default class FormParent extends Component {
 
   constructor(props, initialState, redirect){

      super(props)

      this.initialState = initialState;
      this.state        = initialState;
      this.redirect     = redirect;

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
   }

   /**
    * Realiza a renderização do field do formulario
    * 
    * @param {*} event 
    */
   handleChange(event) {
      const name  = event.target.name; 
      const value = event.target.value;
      this.setState({[name]: value})
   }

   /**
    * Realiza o submit do formulario
    * 
    * @param {*} event 
    */
    handleSubmit() {
      if(this.state.email &&  this.state.password){
         
         // Requisição para login do usuário
         customApi.post('login', {
           email: this.state.email,
           password: this.state.password
         }, (res) => {
            const profiles = res.data
            if(profiles.auth.length === 1){
               login(
                  profiles.auth[0].token,
                  profiles.agent || profiles.admin || profiles.mster
               )
               notification.success('Logado com sucesso!')
               window.location.href = "/"
            } else
               this.props.navigate(this.redirect, { state: res.data })
         }, (error) => {
            notification.error("Credenciais inválidas!")
         })

      }else{
         notification.error("Credenciais inválidas!")
      }
   }
 
   render(formContent) {

		return(
         <>
            {formContent}
         </>
      )
   }
}

