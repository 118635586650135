import React, {Component} from 'react';

import {Link} from 'react-router-dom';

export default class IconGridRoute extends Component {

    render() {
        return (
            <Link to={`${this.props.url}/${this.props.id}`} className="link_grid">
                <i className={`fas ${this.props.icon} azul-athan-dark`}></i>
            </Link>
        )
    }
};