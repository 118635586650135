import ViewParent from '../../core/components/view/ViewParent';
import { useParams, useNavigate } from 'react-router-dom';
import Persona from '../persona/Persona';
import CustomerTableTickets from './CustomerTableTickets';
import CustomerTableContacts from './CustomerTableContacts';
import './customerView.css';

class CustomerView extends ViewParent {    
    constructor(props){
        const state = {
            active: '',
            address: '',
            birth: '',
            category: '',
            cep: '',
            city: '',
            company_id: '',
            country: '',
            cpf: '',
            customer_id: '',
            email: '',
            name: '',
            note: '',
            owner_id: '',
            phone: '',
            state: '',
            tenant_id: ''
        };
        const title         = "Cliente";
        const apiDefault    = "/customer/read/id";
        const routeList     = "/customer";

        super(props, state, title, apiDefault, routeList);
    };
  
    render() {
        return(
            <>
                {super.render(
                    <>
                        
                            <div className="container-fluid p-0">

                                <div className="customer-view-container">
                                    <div className="customer-view-info">
                                        <div className="customer-view-info-item customer-view-persona-container">
                                            <Persona state={this.state} />
                                        </div>
                                    </div>

                                    <div className="customer-view-table">
                                        <div className="customer-view-table-items">                                            
                                            {this.state.customer_id !== '' &&                                            
                                                <CustomerTableTickets id={this.state.customer_id} />                                             
                                            }
                                        </div>
                                        <div className="customer-view-table-items">
                                            {this.state.customer_id !== '' &&
                                                <CustomerTableContacts id={this.state.customer_id} />
                                            }
                                        </div>
                                    </div>
                                </div>                                                 
                            </div>  
                        
                    </>                                     
                )}
           </>
        )
    }
}

const CustomerViewComponent = props => {
    const match = { params: useParams() };
    const navigate = useNavigate();
    
    return <CustomerView match={match} navigate={navigate} {...props} />
}

export default CustomerViewComponent;