import api      from './Api'
import display  from './DisplayLoader'
import Swal     from 'sweetalert2'

/**
 * Erros padrões
 * 
 * @param {*} error 
 */
const DEFAULT_ERROR = (error) => {

    console.error(error);

    if(error != null || error.response != null){

        if(error.response.status === 401){
            window.location.href = "/";
        }

        if(error.response.status === 404 && error.response.data.message){
            return
        }

        else if(error.response.data){
            
            const json = error.response.data

            if(json.hasOwnProperty("errors")){

                const msg = {title: 'Campos Inválidos', 
                             text: json.errors.map(e => `${e.field}: ${e.message}`)};

                Swal.fire(msg);
                return;
            }
        }
    }

    Swal.fire({text: error.response.data.message, type: 'error'});

    console.error(error);
};

//const errorFields = {response:{status: 400, data: {errors:[{field: "campo", message: "Mensagem"}]}}}
//const error       = {response:{status: 400, data: {message: "Erro"}}}
//DEFAULT_ERROR(errorFields)
//DEFAULT_ERROR(error)

/**
 * Customização dos Request da APIs
 */
class CustomApi {

    /**
     * Execução da Apis
     * 
     * @param {*} obj 
     * @param {*} success 
     * @param {*} error 
     */
    executeNew(obj, success, error){
        console.log("🚀 ~ file: CustomApi.js ~ line 58 ~ CustomApi ~ executeNew ~ obj", obj)

        display.show();

        api.request(obj)
            .then(success)
            .catch(error || DEFAULT_ERROR)
            .finally(() => display.hide());
    }

    /**
     * Execução de chamadas GET
     * 
     * @param url
     * @param data
     * @param success
     * @param error
     */
    get = (url, data, success, error) => this.executeNew({method: "GET", url: url, data: data}, success, error);

    /**
     * Execução de chamadas PUT
     * 
     * @param url
     * @param data
     * @param success
     * @param error
     */
    put = (url, data, success, error) => this.executeNew({method: "PUT", url: url, data: data}, success, error);

    /**
     * Execução de chamadas POST
     * 
     * @param url
     * @param data
     * @param success
     * @param error
     */
    post = (url, data, success, error) => this.executeNew({method: "POST", url: url, data: data}, success, error);

    /**
     * Execução de chamadas PATCH
     *
     * @param url
     * @param data
     * @param success
     * @param error
     */
    patch = (url, data, success, error) => this.executeNew({method: "PATCH", url: url, data: data}, success, error);

    /**
     * Execução de chamadas DELETE
     *
     * @param url
     * @param data
     * @param success
     * @param error
     */
    delete = (url, data, success, error) => this.executeNew({method: "DELETE", url: url, data: data}, success, error);
};

const customApi = new CustomApi();

export default customApi;