import React, {Component} from 'react';

export default class HeaderResponsive extends Component {
	
  render() {

		return (			
			<>		
				<div className="navbar-wrapper">                            
					<div className="navbar-toggle d-inline">
						<span  className="navbar-toggler">
							<i className="fas fa-bars"></i>
						</span>
					</div>
				</div>
				
				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-expanded="false" aria-label="Toggle navigation">
					<i className="fas fa-ellipsis-v"></i>
				</button>
			</>
		);
	}
};