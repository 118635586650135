import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import "./menu.css";

export default class MenuAgents extends Component {
	
  render() {

		return (
			<div className="sidebar">
				<div className="sidebar-wrapper">
					
					<div className="logo">
						<Link to="/" className="simple-text logo-mini">
							<img src="assets/img/athan_logo.png" alt="Athan-logo" />
						</Link>
						<Link to="/" className="simple-text logo-normal">
							<img src="assets/img/athan.png" alt="Athan" />
						</Link>
					</div>	
					<ul className="nav">
						<li>
							<Link to="/customer">
								<i className="fas fa-address-card"></i>
								<p>Clientes</p>
							</Link>
						</li>
						<li>
							<Link to="/ticket">
								<i className="fas fa-ticket-alt"></i>
								<p>Tickets</p>
							</Link>
						</li>
						<li>
							<Link to="/atendimentos">
								<i className="fas fa-comments"></i>
								<p>Atendimentos</p>
							</Link>
						</li>
						<li>
							<a data-toggle="collapse" href="#menu-report">
								<i className="fas fa-chart-line"></i>
								<p>Relatórios <b className="caret"></b></p>
							</a>
							<div className="collapse" id="menu-report">
								<ul className="nav">
									<li>
										<a>
											<i className="fas fa-link"></i>
											<p>Opção 1</p>
										</a>
									</li>
									<li>
										<a>
											<i className="fas fa-link"></i>
											<p>Opção 2</p>
										</a>
									</li>
									<li>
										<a>
											<i className="fas fa-link"></i>
											<p>Opção 3</p>
										</a>
									</li>
								</ul>
							</div>                
						</li>
						<li>
							<Link to="/logout">
								<i className="fas fa-sign-out-alt"></i>
								<p>Logout</p>
							</Link>
						</li>
					</ul>
				</div>
			</div>
		);
	}
};