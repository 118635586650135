import React, { Component } from 'react'

import customApi from '../api/CustomApi'
import Swal from 'sweetalert2';
import notification from "../notification/Notification"

import "./form.css"

export default class FormParent extends Component {

    constructor(props, initialState, apiDefault) {

        super(props)

        this.initialState = initialState;
        this.apiDefault = apiDefault;
        this.state = initialState;
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({ email: this.props.searchParams.get('email') })
    }

    /**
     * Realiza o submit do formulario
     * 
     * @param {*} event 
     */
    handleSubmit() {
        
        console.log("🚀 ~ file: formNotification.js:33 ~ FormParent ~ handleSubmit ~ (this.state", this.state)
        if (this.state.email) {
            // Requisição para login do usuário
            customApi.post(this.apiDefault, this.state, (res) => {
                notification.success('Descadastro realizado com sucesso!')
                this.props.navigate('/');
            }, (error) => {
                notification.error('Notificações já desativadas para este email!')
            })

        } else {
            notification.error('Um erro inesperado aconteceu, por favor tente novamente mais tarde!')
        }
    }

    render(formContent) {

        return (
            <>
                {formContent}
            </>
        )
    }
}