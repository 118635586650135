import React from 'react'
import {Row, Form, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { localStoragePersonalized } from '../../core/components/api/Auth';

import FormParent from '../../core/components/form/FormParent'

class CustomerForm extends FormParent {
   
   constructor(props){
      const state         = {
        name: "", 
        phone: "", 
        state: "", 
        email: "", 
        cpf: "", 
        tenant_id: JSON.parse(localStorage.getItem('@wf-User'))['tenant_id']
        ? JSON.parse(localStorage.getItem('@wf-User'))['tenant_id']
        : '', 
        tenantDefault: [],
        owner_id: JSON.parse(localStorage.getItem('@wf-User'))['user_id']
        ?? JSON.parse(localStorage.getItem('@wf-User'))['user_id'] ? JSON.parse(localStorage.getItem('@wf-User'))['admin_id']
        : JSON.parse(localStorage.getItem('@wf-User'))['master_id'],
        category: "", 
        instagram: "",
        companyDefault: [],
        company_id: JSON.parse(localStorage.getItem('@wf-User'))['company_id']
        ? JSON.parse(localStorage.getItem('@wf-User'))['company_id']
        : '',
      }

      const titlePage     = "Cliente"
      const apiDefault    = "customer/create"
      const routeList     = "/customer"

      super(props, state, titlePage, apiDefault, routeList)

      this.company = localStoragePersonalized.getCompanyId
      this.master = localStoragePersonalized.getMasterId
   }


   componentDidMount() {

      if (!this.company && this.master){
        this.selectPreview('/companies/list', 'companyDefault', 'company_id', '')
        this.selectPreview('/tenant/list', 'tenantDefault', 'tenant_id', '')
      } else{
        this.selectPreview('/companies/list', 'companyDefault', 'company_id', '')
      }

    }

   render() {
		return(
         <>
            {super.render(
               <div className="card-body">
                  <Row>
                    <Col xs={4}>
                      <h5>DADOS PESSOAIS</h5>
                    </Col></Row>
                  <br />
                  <Row>
                      <Col md={4}>
                        <Form.Label>Nome:</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Nome completo" defaultValue="" onChange={this.handleChange} />
                      </Col>
                      <Col md={2}>
                        <Form.Label>CPF</Form.Label>
                        <Form.Control type="text" maxLength="14" name="cpf" defaultValue="" onBlur={this.formatCPF} onChange={this.handleChange} />
                      </Col>
                      <Col md={2}>
                        <Form.Label>D.N.</Form.Label>
                        <Form.Control type="date" name="birth" defaultValue="" onChange={this.handleChange} />
                      </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={4}>
                      <h5>DADOS DE CONTATO</h5>
                    </Col></Row>
                  <br />
                  <Row>
                    <Col md={2}>
                        <Form.Label>Telefone</Form.Label>
                        <Form.Control type="tel" name="phone" placeholder="+5511000000000" defaultValue="" onChange={this.handleChange}/>
                    </Col>
                    <Col md={4}>
                        <Form.Label>email</Form.Label>
                        <Form.Control type="email" name="email" defaultValue="" onChange={this.handleChange} />
                    </Col>
                    <Col md={2}>
                      <Form.Label>Instagram</Form.Label>
                      <Form.Control type="text" name="instagram" placeholder="@instagram" defaultValue="" onChange={this.handleChange} />
                    </Col>
                    </Row>
                    <br />
                    <Row>
                    <Col xs={4}>
                      <h5>ENDEREÇO</h5>
                    </Col></Row>
                  <br />
                  <Row>
                    <Col md={2}>
                      <Form.Label>CEP</Form.Label>
                      <Form.Control type="text" name="cep" defaultValue="" />
                    </Col>
                    <Col md={2}>
                      <Form.Label>Número</Form.Label>
                      <Form.Control type="text" name="numero" defaultValue="" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control type="text" name="address" defaultValue="" onChange={this.handleChange} />
                    </Col>
                    <Col md={2}>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control type="text" name="city" defaultValue={this.state.city} onChange={this.handleChange} />
                    </Col>
                    <Col md={2}>
                      <Form.Label>Estado</Form.Label>
                      <Form.Control type="text" name="state" defaultValue={this.state.state} onChange={this.handleChange} />
                    </Col>
                    <Col md={2}>
                      <Form.Label>País</Form.Label>
                      <Form.Control type="text" name="country" defaultValue={this.state.country} onChange={this.handleChange} />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                     <Col xs={4}>
                      <h5>DETALHES</h5>
                     </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col md={2}>
                      <Form.Label>Categoria</Form.Label>
                      <Form.Control as="select" name="category" defaultValue={this.state.category} onChange={this.handleChange} >
                        <option value="">Selecione uma categoria</option>
                        <option value="1">Baixa</option>
                        <option value="2">Média</option>
                        <option value="3">Alta</option>
                      </Form.Control>
                    </Col>
                    {
                        this.company ? (
                           <div></div>
                           ) :
                            <Col md={2}>
                              <Form.Label>Grupo</Form.Label>
                              <Form.Control as="select" name="company_id" defaultValue={this.state.company_id} onChange={this.handleChange} >
                                 <option value="">Selecione o Grupo</option>
                                 {this.state.companyDefault.map(op => {
                                    return <option key={op.id} value={op.id}>{op.name}</option>
                                 })}
                              </Form.Control>
                           </Col>
                    }

                    {
                      this.master ? (
                      <Col md={3}>
                              <Form.Label>Tenant</Form.Label>
                              <Form.Control as="select" name="tenant_id" defaultValue={this.state.responsavel} onChange={this.handleChange} >
                                 <option value="">Selecione o tenant</option>
                                 {this.state.tenantDefault.map(op => {
                                    return <option key={op.tenant_id} value={op.tenant_id}>{op.tenant_name}</option>
                                 })}
                              </Form.Control>
                           </Col>
                      ) :
                      <div></div>
                    }
                  </Row> 
                  <Row>
                    <Col md={6}>
                      <Form.Label>Anotações</Form.Label>
                      <Form.Control as="textarea" rows={6} name="notes" placeholder="Insira aqui informações importantes referentes ao cliente" defaultValue={this.state.notes} onChange={this.handleChange} />
                    </Col>
                  </Row>
               </div>
            )}
         </>
      )
   }
}

const CustomerFormComponent = props => {
   const navigate = useNavigate()
 
   return <CustomerForm navigate={navigate} {...props} />
}

export default CustomerFormComponent