import React, {Component} from 'react';

import HeaderResponsive from './HeaderResponsive'
//import OptionRight from '@/core/template/header/options/OptionRight'

export default class Header extends Component {
	
  render() {
		return (			
			<nav className="navbar navbar-expand-lg navbar-absolute navbar-transparent">
				<div className="container-fluid">
					
					<HeaderResponsive />

								
				</div>
			</nav>  
		);
	}
};