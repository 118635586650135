import React from 'react'
import { Card, CardGroup } from 'react-bootstrap'
import FormLoginPhase2 from '../../core/components/form/formLoginPhase2'
import { useParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom';

class Redirect extends FormLoginPhase2 {

   constructor(props) {
      const state = { agent: "", admin: "", master: "", auth: "", name: { agent: "Agente", admin: "Administrador", master: "Master" } }
      const redirect = '/login/redirect'
      super(props, state, redirect)
   }

   render() {
      return (
         <>
            {
               super.render(
                  <>
                     {<div className='Signin main-panel'>
                        <Card style={{ width: '60vw', height: '70vh', backgroundColor: '#f1fcff', borderRadius: '10px', textAlign:'center' }}
                        >
                           <div className="login-container" style={{ margin: '2rem' }}>
                              <h2>Por favor, selecione o perfil de usuário que deseja utilizar</h2>
                           </div>

                           {
                              <div className="card-perfis" style={{ width: '80%', margin: '0 auto' }} >
                                 {
                                    this.state.auth.length > 0 ? this.state.auth.map((auth, indice) => (
                                       <Card key={indice} className='card--profile' style={{borderRadius: '40px', width: '10vws'}} onClick={ () => this.profileSelect(indice)}>
                                          <Card.Body>
                                             <Card.Title><img className='rounded-img' alt=""  src="https://img.freepik.com/vetores-premium/perfil-de-avatar-de-homem-no-icone-redondo_24640-14044.jpg" /></Card.Title>
                                             <Card.Text style={{color: 'rgb(255 155 109)', fontWeight: 'bold', fontStyle: 'italic'}}>
                                                {this.state.name[auth.type]}
                                             </Card.Text>
                                             <Card.Text style={{color: '#676767', marginTop: '1rem',  textTransform: 'capitalize'}}>
                                                {auth.tenant}
                                             </Card.Text>
                                          </Card.Body>
                                       </Card>
                                    )) : ''
                                 }
                              </div>

                           }


                        </Card>





                     </div>}
                  </>
               )
            }
         </>
      )
   }
}


const RedirectComponent = props => {
   const match = {
      params: useParams()
   }
   const navigate = useNavigate()
   const location = useLocation()


   const [searchParams] = useSearchParams();

   return <Redirect match={match} location={location} navigate={navigate} searchParams={searchParams} {...props} />
}

export default RedirectComponent