import React       from 'react'
import { Button }  from 'react-bootstrap';

import IconGridRoute    from '../../core/components/icon/IconGridRoute'
import GridParent       from "../../core/components/grid/GridParent"

export default class CustomerTableTickets extends GridParent {
     
   constructor({ id }){      
      const titlePage  = "Tickets"
      const apiList    = `/ticket/pages/customer/${id}`
      const routeForm  = "/ticket/form"

      super(titlePage, apiList, routeForm)
   }

   render() {

      return (
         <>
           {super.render(
               <>
                  <div className="card">
                     <div className="card-body">
                        <div className="row">
                           <div className="col-lg-3">
                              <input type="text" 
                                 name="responsavel" 
                                 className="form-control" 
                                 maxLength="40" 
                                 placeholder="Buscar por responável" 
                                 onChange={this.handleFilter} 
                              />           
                           </div>
                           <div className="col-lg-3">
                              <select className="form-control" name="status" onChange={this.handleFilter}>
                                 <option value="">TODOS</option>
                                 <option value="A FAZER">A FAZER</option>
                                 <option value="EM PROGRESSO">EM PROGRESSO</option>
                                 <option value="NEGOCIAÇÃO">NEGOCIAÇÃO</option>
                                 <option value="CONCLUÍDO">CONCLUÍDO</option>
                                 <option value="BLOQUEADO">BLOQUEADO</option>
                              </select>
                           </div>   
                           <div className="col-lg-3">
                              <Button className="btn m-0" onClick={this.sendRequest}>
                                 <i className="fas fa-search"></i>
                              </Button>
                           </div>
                        </div>            
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-body">   
                        <div className="table-responsive">
                           <table className="table table-striped table-hover text-nowrap">
                              <thead>
                                 <tr>
                                    <th>ID</th>
                                    <th>Título</th>
                                    <th>Responsável</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Ações</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.data.map(row => 
                                    <tr key={row.ticket_id}>
                                       <td>{row.ticket_id}</td>
                                       <td>{row.title}</td>
                                       <td>{row['resp.name']}</td>
                                       <td>{row['resp.email']}</td>
                                       <td>{row.status}</td>
                                       <td align="right">
                                          <IconGridRoute url={this.routeForm} id={row.ticket_id} icon="fa-edit" />
                                       </td>
                                    </tr>
                                 )}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </>
            )} 
         </>
      );
   }
};