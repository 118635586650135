import React, {Component} from 'react';
import {Route, Routes} from "react-router-dom";

import Home from '../../../modules/home/Home'

import Customer from '../../../modules/customer/Customer'
import CustomerForm from '../../../modules/customer/CustomerForm'
import CustomerFormEdit from '../../../modules/customer/CustomerFormEdit'
import CustomerView from '../../../modules/customer/CustomerView';

import Contact from '../../../modules/contacts/Contact';
import ContactView from '../../../modules/contacts/ContactView';

import Ticket from '../../../modules/ticket/Ticket';
import TicketForm from '../../../modules/ticket/TicketForm';
import TicketFormEdit from '../../../modules/ticket/TicketFormEdit';

import Logout from '../../../modules/signin/logout';
import ForgotPassword from '../../../modules/signin/forgot-password';
import UnsubscribeNootification from '../../../modules/notification/unsubscribe-notification'

const Route404 = ({location}) => (
    <div><h4>Rota não encontrada...<br /><code className="green">{location.pathname}</code></h4></div>
);

export default class RouteresAgent extends Component {
    
    render() {
        return (
            <Routes>
                <Route path="/"                      element={<Home />} />

                <Route path="/customer"              element={<Customer />} />
                <Route path="/customer/form"         element={<CustomerForm />} />
                <Route path="/customer/form/:id"     element={<CustomerFormEdit />} />
                <Route path="/customer/view/:id"     element={<CustomerView />} />

                <Route path="/ticket"                element={<Ticket />} />
                <Route path="/ticket/form"           element={<TicketForm />} />
                <Route path="/ticket/form/:id"       element={<TicketFormEdit />} />
                
                <Route path="/atendimentos"                   element={<Contact />} />
                <Route path="/atendimentos/detalhes/:id"      element={<ContactView />}  />

                <Route path="/forgot_password"       element={<ForgotPassword />} />
                <Route path="/logout"                element={<Logout />} />
                <Route path="/unsubscribe"           element={<UnsubscribeNootification />} />

                <Route component={Route404} />
            </Routes>
        )
    }
};