import React, {Component} from 'react';

import {Link} from 'react-router-dom'
export default class PageHeader extends Component {

    render() {

        let showButton
        
        if(this.props.routeForm)
            showButton = <Link to={this.props.routeForm} className="btn btn-primary"><i className="fas fa-plus-circle"></i> Novo</Link>
        else if(this.props.routeList)
            showButton = <Link to={this.props.routeList} className="btn btn-primary"><i className="fas fa-backspace"></i> Voltar</Link>
        
        return (
            <div className="row">
                <div className="col-6">
                    <h3 className="mb-0">
                        <span className="azul-athan-dark">
                            {this.props.title}
                        </span>
                    </h3>
                </div>
                <div className="col-6 text-right">{showButton}</div>
            </div>
        )
    }
};