import React       from 'react'
import { Button }  from 'react-bootstrap';

import ChangeStatus     from '../../core/components/grid/ChangeStatus'
import IconGridRoute    from '../../core/components/icon/IconGridRoute'
import IconLabelStatus  from '../../core/components/icon/IconLabelStatus'
import IconLabelTicket  from '../../core/components/icon/IconLabelTicket'
import GridParent       from "../../core/components/grid/GridParent"

export default class Ticket extends GridParent {
     
   constructor(){
      const titlePage     = "Tickets"
      const apiList       = "/ticket/pages"
      const routeForm     = "/ticket/form" 
      const apiStatus     = "/ticket/update/status"

      super(titlePage, apiList, routeForm, apiStatus)
   }

   render() {
      return (
         <>
           {super.render(
               <>
                  <div className="card">
                     <div className="card-body">
                        <div className="row">
                           <div className="col-lg-3">
                              <input type="text" 
                                    name="title" 
                                    className="form-control" 
                                    maxLength="40" 
                                    placeholder="Buscar título" 
                                    onChange={this.handleFilter} />           
                           </div>
                           <div className="col-lg-2">
                              <select className="form-control" name="companyId" defaultValue="" onChange={this.handleFilter}>
                                  <option value="">Buscar Empresa</option> 
                                {this.state.company.map(companies => {
                                      return <option key={companies.id} value={companies.id}>{companies.name}</option>
                                       })
                                }
                              </select>
                            </div>
                           <div className="col-lg-1.5">
                              <select className="form-control" name="status" onChange={this.handleFilter}>
                                 <option value="">Buscar Status</option>
                                 <option value="A FAZER">A FAZER</option>
                                 <option value="EM PROGRESSO">EM PROGRESSO</option>
                                 <option value="NEGOCIAÇÃO">NEGOCIAÇÃO</option>
                                 <option value="CLIENTE">CLIENTE</option>
                                 <option value="CONCLUÍDO">CONCLUÍDO</option>
                                 <option value="BLOQUEADO">BLOQUEADO</option>
                              </select>
                            </div>
                           <div className="col-lg-3">
                              <Button className="btn" onClick={this.sendRequest}>
                                 <i className="fas fa-search"></i>
                              </Button>
                           </div>
                        </div>            
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-body">   
                        <div className="table-responsive">
                           <table className="table table-striped table-hover text-nowrap">
                              <thead>
                                 <tr>
                                    <th></th>
                                    <th>ID</th>
                                    <th></th>
                                    <th>Última Atualização</th>
                                    <th>Título</th>
                                    <th>Responsável</th>
                                    <th>Cliente</th>
                                    <th>Empresa</th>
                                    <th>STATUS</th>
                                    <th>Horas</th>
                                    <th></th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.data.map(row => 
                                    {
                                        return  <tr key={row.ticket_id}>
                                        <td>
                                          <IconLabelTicket value={row.priority} />
                                        </td>
                                        <td>{row.ticket_id}</td>
                                        <td></td>
                                        <td>{row.update_at}</td>
                                        <td>{row.title}</td>
                                        <td>{row['resp.name']}</td>
                                        <td>{row['ct.name']}</td>
                                        <td>{row['ct.company.name']}</td>
                                        <td>
                                          <IconLabelTicket value={row.status} /> {row.status}
                                        </td>
                                        <td>{row.used_time}</td>
                                        <td align="right">
                                           <IconGridRoute url={this.routeForm}  id={row.ticket_id} icon="fa-edit" />
                                        </td>
                                     </tr>
                                    }
                                 )}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </>
            )} 
         </>
      );
   }
};