import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { Row, Form, Button } from 'react-bootstrap';

import customApi from '../api/CustomApi';
import { useParams, useNavigate } from 'react-router-dom';


import "./form.css"

class FormComment extends Component {
   state = {
      comments: [],
      comment: '',
      useType: ''
   }
   
   constructor(props, comments) {
      super(props)
      this.apiDefault = `/ticket/${this.state.useType}/comment/create`
      this.handleChange = this.handleChange.bind(this);
      this.handleClick = this.handleClick.bind(this);
   }

   componentDidMount() {
      this.selectPreview('ticket/comments/read/' + this.props.match.params.id, 'comments')
   }

   /**
 * @description Recupera dados para formulário do tipo select
 * @param {string} api URL da api
 * @param {string} field variável que irá receber uma lista de objetos
 * @param {string} defaultValue variável responsável pelo defaultValue
 * @param {string} value campo do retorno que contem o valor desejado
 */
   selectPreview(api, field, defaultValue = '', value = '') {
      customApi.get(`${api}`, {}, (response) => {

         this.setState({ [field]: response.data })

         if (defaultValue) {
            this.setState({ [defaultValue]: response.data[0][value] })
         }

      });
   }

   /**
    * Realiza a renderização do field do formulario
    * 
    * @param {*} event 
    */
   handleChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.setState({ [name]: value })
   }

   /**
   * Monta o payload necessário para envio da requisição ao servidor
   */
   payloadFormatted() {

      const user = JSON.parse(localStorage.getItem('@wf-User'))
     
      const useType = user.master_id 
         ? ['masterId', 'master_id', 'master'] 
         : ['userId', 'user_id', 'user']
      
      return {
         body: {
            ticketId: this.props.match.params.id,
            [useType[0]]: user[useType[1]],
            comment: this.state.comment
         },
         config: {
            endpoint: `/ticket/${useType[2]}/comment/create`
         }
      }
   }

   /**
    * Realiza o submit do formulario
    * 
    * @param {*} event 
   */
   handleClick(event) {
      const payload = this.payloadFormatted()

      customApi.post(payload.config.endpoint, payload.body || {}, (response) => {
         response.data.email = JSON.parse(localStorage.getItem('@wf-User'))['email']
         this.setState({
            comments: [...this.state.comments, response.data],
            comment: ''
         })
         Swal.fire('Comentário adicionado!')
      });
   }

   render() {
      return (
         <>
            <div className="row">
               <div className="card">
                  <div className="card-body card-comment" style={{ background: '#f7fdff' }}>
                     <div className="col-md-4 animated fadeIn " key={this.state.ticket_id}>
                        {
                           this.state.comments.map((value, i) => {
                              const useType = value.master_id || value.masterId ? '#ebf1fa' : '#FFFFFF'
                              return (
                                 <div className="card" key={i} value={i} style={{ width: '50vw', background: useType, marginLeft: '0px' }}>
                                    <div className="card-header">
                                       {value.name} ({value.email})
                                       <p style = {{fontSize: '12px'}}>{new Date(value.created_at).toLocaleString()}</p>
                                    </div>
                                    <div className="card-body">
                                       <Form.Label style={{ whiteSpace: 'pre-line'}}>{value.comment}</Form.Label>
                                    </div>
                                 </div>
                              )
                           })
                        }
                     </div>
                     <div className="card-body" style={{ background: '#ffffff' }}>
                        <Row>
                           <Form.Control as="textarea" rows={6} name="comment" value={this.state.comment} onChange={this.handleChange} />
                           <Button onClick={this.handleClick} className="btn">
                              <i className="fas fa-comment-alt"></i> Comentar
                           </Button>
                        </Row>
                     </div>
                  </div>
               </div>
            </div>
         </>
      )
   }
}

const TicketCommentComponent = props => {
   const match = {
      params: useParams()
   }
   const navigate = useNavigate()

   return <FormComment match={match} navigate={navigate} {...props} />
}

export default TicketCommentComponent