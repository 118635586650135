import React, {Component} from 'react';

import "./icon.css"

export default class IconLabelTicket extends Component {

    render() {

        let icon

        switch (this.props.value) {
        	case 'A FAZER':
        		icon = <i className="fas fa-business-time"></i>
        		break;

        	case 'EM PROGRESSO':
        		icon = <i className="fas fa-spinner"></i>
        		break;

        	case 'NEGOCIAÇÃO':
        		icon = <i className="fas fa-handshake"></i>
        		break;

        	case 'CONCLUÍDO':
        		icon = <i className="fas fa-check-circle"></i>
        		break;

        	case 'BLOQUEADO':
        		icon = <i className="fas fa-ban"></i>
        		break;

        	case 'Alta':
        		icon = <i className="fas fa-exclamation red"></i>
        		break;

            case 'CLIENTE':
                icon = <i className="fas fa-stopwatch"></i>
                break;

        	default:
        		break;
        }

        return (icon)
	}
}