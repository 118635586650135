import { Row, Form, Col} from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import FormParent from '../../core/components/form/FormParent'

class TicketForm extends FormParent {   
   constructor(props){
      const state         = { 
        external_protocol: "", 
        description: "", 
        created_by: JSON.parse(localStorage.getItem('@wf-User'))['user_id']
        ? JSON.parse(localStorage.getItem('@wf-User'))['user_id']
        : '', 
        responsavel: "", 
        status: "A FAZER", 
        customer: "",
        classification_id: 1,
        priority: "",
        agentDefault: [],
        costumerDefault: [],
        title: "",
        files: [],
        files_details: []
    }
      const titlePage     = "Ticket"
      const apiDefault    = "/ticket/create"
      const routeList     = "/ticket"
      
      super(props, state, titlePage, apiDefault, routeList)
   }

   componentDidMount() {
      console.log("STATE ATUAL", this.state)
      this.selectPreview('/user/list', 'agentDefault', 'responsavel', 'user_id')
      this.selectPreview('/customer/list', 'costumerDefault', 'customer', 'customer_id')
   }

   render() {

    console.log("FILES_NEW", this.state)

      return (
         <>
            {super.render(
                     <div className="card-body">
                        <Row>
                           <Col xs={4}>
                              <h1>
                              <Form.Label>DADOS DO TICKET</Form.Label>
                              </h1>
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={4}>
                              <Form.Label>Título</Form.Label>
                              <Form.Control type="text" name="title" maxLength="35" defaultValue={this.state.title} onChange={this.handleChange} required />
                           </Col>
                           <Col xs={2}>
                              <Form.Label>Protocolo Associado</Form.Label>
                              <Form.Control type="text" name="external_protocol" defaultValue={this.state.external_protocol} onChange={this.handleChange} />
                           </Col>
                           <Col xs={2}>
                              <Form.Label>Criticidade</Form.Label>
                              <Form.Control as="select" name="priority" defaultValue={this.state.priority} onChange={this.handleChange} required >
                                    <option value="">Selecione</option>
                                    <option value="Baixa">BAIXA</option>
                                    <option value="Média">MÉDIA</option>
                                    <option value="Alta">ALTA</option>
                              </Form.Control>
                           </Col>
                           <Col xs={2}>
                                <Form.Label>Status</Form.Label>
                                <Form.Control as="select" name="status" defaultValue={this.state.status} onChange={this.handleChange}   >
                                    <option value="A FAZER">A FAZER</option>
                                    <option value="EM PROGRESSO">EM PROGRESSO</option>
                                    <option value="NEGOCIAÇÃO">NEGOCIAÇÃO</option>
                                    <option value="CLIENTE">CLIENTE</option>
                                    <option value="CONCLUÍDO">CONCLUÍDO</option>
                                    <option value="BLOQUEADO">BLOQUEADO</option>
                                </Form.Control>
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={6}>
                              <Form.Label>Descrição</Form.Label>
                              <Form.Control as="textarea" rows={6} name="description" defaultValue={this.state.description} onChange={this.handleChange} required/>
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={2}>
                                <div className="drop-container">
                                  <Form.Control type="file" name="files" defaultValue={this.state.files} onChange={this.handleUpload}></Form.Control>
                                  <Form.Label><i className="fas fa-upload"></i> Anexar</Form.Label></div>
                           </Col>
                           <Col xs={4}>
                                {this.state.files_details.map((image, index) =>
                                  <Row>
                                    <Form.Label key={index}>{image.detail_name}</Form.Label>
                                  </Row>
                                  )}
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={4}>
                              <h1>
                                 <Form.Label>PARTICIPANTES</Form.Label>
                              </h1>
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={4}>
                                 <Form.Label>Criador</Form.Label>
                                 {JSON.parse(localStorage.getItem('@wf-User'))['user_id'] 
                                    ? <Form.Control type="text" name="created_by" defaultValue={JSON.parse(localStorage.getItem('@wf-User'))['name']} onChange={this.handleChange} disabled/>
                                    : <Form.Control as="select" name="created_by" defaultValue={this.state.created_by}  placeholder="Selecione um agente" onChange={this.handleChange} >
                                            <option  value="">Selecione um agente</option>
                                            {this.state.agentDefault.map(criador => {
                                                return <option key={criador.user_id} value={criador.user_id}>{criador.name}</option>
                                            })}
                                      </Form.Control>
                                 }
                           </Col>
                           <Col xs={4}>
                                 <Form.Label>Responsável</Form.Label>
                                 {JSON.parse(localStorage.getItem('@wf-User'))['tenant_id'] === '1'
                                  ? <Form.Control as="select" name="responsavel" defaultValue={this.state.responsavel} onChange={this.handleChange} >
                                      <option value="">Selecione um departamento</option>
                                      <option value="15">Suporte</option>
                                      <option value="16">Desenvolvimento</option>
                                    </Form.Control>
                                  :
                                 <Form.Control as="select" name="responsavel" defaultValue={this.state.responsavel} onChange={this.handleChange} >
                                    <option  value="">Selecione um agente</option>
                                    {this.state.agentDefault.map(resp => {
                                        return <option key={resp.user_id} value={resp.user_id}>{resp.name}</option>
                                    })}
                                 </Form.Control>
                               }
                           </Col>
                        </Row> 
                        <Row>
                            <Col xs={4}>
                                <Form.Label>Cliente</Form.Label>
                                <Form.Control as="select" name="customer" defaultValue={this.state.customer} onChange={this.handleChange} >
                                    <option  value="">Selecione um cliente</option>
                                    {this.state.costumerDefault.map(cliente => {
                                        return <option key={cliente.customer_id} value={cliente.customer_id}>{cliente.name}</option>
                                    })}
                                </Form.Control>
                            </Col>
                        </Row>
               </div>
            )}
         </>
      )
   }
}

const TicketFormComponent = props => {
   const navigate = useNavigate()

   return <TicketForm navigate={navigate} {...props} />
}

export default TicketFormComponent