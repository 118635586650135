import React, {Component} from 'react'
import customApi     from '../api/CustomApi'
import notification  from "../notification/Notification"
import { login }         from '../api/Auth'

import "./form.css"

export default class FormLoginPhase2 extends Component {
 
   constructor(props, initialState, redirect){

      super(props)

      this.initialState = initialState;
      this.state        = initialState;
      this.redirect     = redirect;

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.profileSelect = this.profileSelect.bind(this);
   }

    componentDidMount() {      
        this.setState(this.props.location.state)
    } 

    profileSelect(indice) {
       const profileSelect = this.state.auth[indice]
       login(profileSelect.token, profileSelect)
       notification.success('Logado com sucesso!')
       window.location.href = "/"
   }

    profilesDidMount(profiles) {

        const validProfiles = Object.keys(profiles)
            .filter(prop => profiles[prop])
            .map(prop => profiles[prop].auth)
            .reduce((acc, curr) => {
                if (Array.isArray(curr)) {
                  return acc.concat(curr);
                }
                return acc.concat([curr]);
            }, [])
            
        return validProfiles
    }

   /**
    * Realiza a renderização do field do formulario
    * 
    * @param {*} event 
    */
   handleChange(event) {
      const name  = event.target.name; 
      const value = event.target.value;
      this.setState({[name]: value})
   }

   /**
    * Realiza o submit do formulario
    * 
    * @param {*} event 
    */
    handleSubmit() {
      if(this.state.email &&  this.state.password){
         
         // Requisição para login do usuário
         customApi.post('login', {
           email: this.state.email,
           password: this.state.password
         }, (res) => {
         console.log("🚀 ~ file: formLogin.js:47 ~ FormParent ~ handleSubmit ~ res", res.data)

            this.props.navigate(this.redirect, { state: res.data })
         //   const token = res.data.token
         //   const user  = res.data
         //   login(token, user)
         //   notification.success(user)
         //   window.location.href = "/"
   
         }, (error) => {
            notification.error("Credenciais inválidas!")
         })

      }else{
         notification.error("Credenciais inválidas!")
      }
   }
 
   render(formContent) {

		return(
         <>
            {formContent}
         </>
      )
   }
}

