import React from 'react'
import {Row, Form, Col } from 'react-bootstrap';

import FormParent from '../../core/components/form/FormParent'

export default class TenantForm extends FormParent {
   
   constructor(props){
      const state         = {tenant_name: "", tenant_domain: "", max_users: "", db_ip: "", db_port: "", tenant_status: "1"}
      const titlePage     = "Cliente"
      const apiDefault    = "/tenant/create"
      const routeList     = "/customer"

      super(props, state, titlePage, apiDefault, routeList)
   }
 
   render() {
		return(
         <>
            {super.render(
                     <div className="card-body">
                        <Row>
                           <Col xs={4}>
                              <h1>
                              <Form.Label>DADOS DO CLIENTE</Form.Label>
                              </h1>
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={4}>
                              <Form.Label>Nome</Form.Label>
                              <Form.Control type="text" name="tenant_name" defaultValue={this.state.tenant_name} onChange={this.handleChange} />
                           </Col>
                           <Col xs={4}>
                              <Form.Label>CNPJ</Form.Label>
                              <Form.Control type="text" name="cnpj" defaultValue={this.state.cnpj} onChange={this.handleChange} />
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={4}>
                              <Form.Label>Responsável</Form.Label>
                              <Form.Control type="text" name="responsavel" defaultValue={this.state.responsavel} onChange={this.handleChange} />
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={4}>
                              <h1>
                                 <Form.Label>DADOS TÉCNICOS</Form.Label>
                              </h1>
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={4}>
                                 <Form.Label>IP</Form.Label>
                                 <Form.Control type="text" name="db_ip" defaultValue={this.state.db_ip} onChange={this.handleChange} />
                           </Col>
                           <Col xs={2}>
                                 <Form.Label>Porta</Form.Label>
                                 <Form.Control type="number" name="db_port" defaultValue={this.state.db_port} onChange={this.handleChange} />
                           </Col>
                           <Col xs={4}>
                              <Form.Label>Domínio</Form.Label>
                              <Form.Control type="url" name="tenant_domain" defaultValue={this.state.tenant_domain} onChange={this.handleChange} />
                           </Col>
                           <Col xs={2}>
                              <Form.Label>Qtd Licenças</Form.Label>
                              <Form.Control type="number" name="max_users" defaultValue={this.state.max_users} onChange={this.handleChange} />
                           </Col>
                        </Row> 
               </div>
            )}
         </>
      )
   }
}