import React, {Component}        from 'react'
//import {Form} from 'react-bootstrap';

import customApi     from '../api/CustomApi'
import PageHeader    from '../pageHeader/PageHeader'

// import "./form.css"

export default class ViewParent extends Component {
 
   constructor(props, initialState, title, apiDefault, routeList){
      
      super(props)

      this.initialState = initialState;
      this.title        = title;
      this.apiDefault   = apiDefault;
      this.routeList    = routeList;

      this.state        = initialState;
   }
   
   componentDidMount() {
      this.handleView()
   }

   /**
    * @description Recupera dados para formulário do tipo select
    * @param {string} api URL da api
    * @param {string} field variável que irá receber uma lista de objetos
    * @param {string} defaultValue variável responsável pelo defaultValue
    * @param {string} value campo do retorno que contem o valor desejado
    */
   selectPreview(api, field, defaultValue = '', value = '') {
      customApi.get(`${api}`, {}, (response) => {
         
         this.setState({[field]: response.data})
         
         if(defaultValue){
            this.setState({[defaultValue]: response.data[0][value]})
         }
         
      });
   }

   /**
    * Realiza a visualização dos dados
    */
   handleView(){
      let id = ""
    
      try {
          id = this.props.match.params.id
      } catch (error){
         console.log("🚀 ~ file: FormParent.js ~ line 42 ~ FormParent ~ handleView ~ error", error)
      }
  

      if(id){
         customApi.get(`${this.apiDefault}/${id}`, {}, (response) => {
            console.log("🚀 ~ file: FormParent.js:90 ~ FormParent ~ handleView ~ response", response)
            return this.setState(response.data)
         })
      }  
   }
   
 
   render(viewContent) {

		return(
         <>
            <PageHeader title={this.title} routeList={this.routeList} />

            <div>


               <div className="card">
                  
                  {viewContent}
                  
               </div>
            </div>
         </>
      )
   }
}